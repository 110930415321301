:root {
	--litepicker-container-months-color-bg: #fff;
	--litepicker-container-months-box-shadow-color: #ddd;
	--litepicker-footer-color-bg: #fafafa;
	--litepicker-footer-box-shadow-color: #ddd;
	--litepicker-tooltip-color-bg: #fff;
	--litepicker-month-header-color: #{$color-green-primary};
	--litepicker-button-prev-month-color: #{$color-brand-secondary};
	--litepicker-button-next-month-color: #{$color-brand-secondary};
	--litepicker-button-prev-month-color-hover: #{$color-brand-primary};
	--litepicker-button-next-month-color-hover: #{$color-brand-primary};
	--litepicker-month-width: calc(var(--litepicker-day-width) * 7); // 7 days
	--litepicker-month-weekday-color: #{$color-brand-primary};
	--litepicker-month-week-number-color: #{$color-brand-primary};
	--litepicker-day-width: 36px;
	--litepicker-multiselect-day-size-selected: 36px;
	--litepicker-day-color: #{$color-green-primary};
	--litepicker-day-color-hover: #{$color-brand-secondary};
	--litepicker-multiselect-is-selected-color: #{$color-white};
	--litepicker-multiselect-is-selected-color-bg: #{$color-brand-secondary};
	--litepicker-is-today-color: #{$color-brand-secondary};
	--litepicker-is-in-range-color: #{$color-orange-light};
	--litepicker-is-locked-color: #{$color-green-light};
	--litepicker-is-start-color: #fff;
	--litepicker-is-start-color-bg: #{$color-brand-secondary};
	--litepicker-is-end-color: #fff;
	--litepicker-is-end-color-bg: #{$color-brand-secondary};
	--litepicker-button-cancel-color: #fff;
	--litepicker-button-cancel-color-bg: #9e9e9e;
	--litepicker-button-apply-color: #fff;
	--litepicker-button-apply-color-bg: #{$color-brand-secondary};
	--litepicker-button-reset-color: #909090;
	--litepicker-button-reset-color-hover: #{$color-brand-secondary};
	--litepicker-highlighted-day-color: #{$color-green-primary};
	--litepicker-highlighted-day-color-bg: #{$color-orange-light};
}

// litepicker for platform only
@include below-laptop {
	.navigation__search-form--lite-picker,
	.clinic-booking-form--lite-picker,
	.home-hero-search-modal {
		.litepicker {
			.container__days {
				& > div,
				.day-item {
					width: calc(100% / 7);
				}
			}

			.container__main {
				width: 100%;
			}

			.container__months {
				width: 100%;
				border: 0;
			}

			.month-item {
				width: 100%;
			}
		}
	}
}
