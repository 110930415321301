.label-item {
	padding: 4px 12px;
	@include font-size(14px, 24px);
	font-weight: $font-weight-medium;
	border-radius: 50px;
	border: 1px solid transparent;
	display: inline-flex;
	align-items: center;
	vertical-align: middle;

	&.label-inactive {
		border-color: $label-inactive-color;
		color: $label-inactive-color;
		background-color: $label-inactive-background-color;
	}

	&.label-completed {
		color: $label-completed-text-color;
		background-color: $label-completed-background-color;
	}
}
