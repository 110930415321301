/***************************************
    Colors
****************************************/
/***************************************
    Typography
****************************************/
/***************************************
    Containers
****************************************/
/***************************************
    Breakpoints
****************************************/
/**
These can be used to scale down some values.
eg. desktop-padding: 120px; -> laptop-padding: (desktop-padding * laptop-desktop-ratio);
 */
@import '~litepicker/dist/css/litepicker.css';
@import '~litepicker/dist/css/litepicker.css';
@import '~litepicker/dist/css/litepicker.css';
.c-brand-primary {
  color: #1c5335 !important;
}

.c-brand-secondary {
  color: #e86825 !important;
}

.c-green-primary {
  color: #1c5335 !important;
}

.c-green-medium {
  color: #6b8f7c !important;
}

.c-green {
  color: #cee1c6 !important;
}

.c-green-light {
  color: #bacbc2 !important;
}

.c-green-lighter {
  color: #e7f2e3 !important;
}

.c-orange-dark {
  color: #c84603 !important;
}

.c-orange-primary {
  color: #e86825 !important;
}

.c-orange-medium {
  color: #f6c3a8 !important;
}

.c-orange-light {
  color: #ffebe1 !important;
}

.c-yellow-main {
  color: #ffb31b !important;
}

.c-yellow-light {
  color: #fff3db !important;
}

.c-red-main {
  color: #c80303 !important;
}

.c-red-light {
  color: #fff3db !important;
}

.c-gray-main {
  color: #c9c9c9 !important;
}

.c-gray-light {
  color: #f8f8f8 !important;
}

.c-gray-brown-light {
  color: #f8f3f0 !important;
}

.c-white {
  color: #fff !important;
}

.c-transparent {
  color: transparent !important;
}

.bg-brand-primary {
  background-color: #1c5335 !important;
}

.bg-brand-secondary {
  background-color: #e86825 !important;
}

.bg-green-primary {
  background-color: #1c5335 !important;
}

.bg-green-medium {
  background-color: #6b8f7c !important;
}

.bg-green {
  background-color: #cee1c6 !important;
}

.bg-green-light {
  background-color: #bacbc2 !important;
}

.bg-green-lighter {
  background-color: #e7f2e3 !important;
}

.bg-orange-dark {
  background-color: #c84603 !important;
}

.bg-orange-primary {
  background-color: #e86825 !important;
}

.bg-orange-medium {
  background-color: #f6c3a8 !important;
}

.bg-orange-light {
  background-color: #ffebe1 !important;
}

.bg-yellow-main {
  background-color: #ffb31b !important;
}

.bg-yellow-light {
  background-color: #fff3db !important;
}

.bg-red-main {
  background-color: #c80303 !important;
}

.bg-red-light {
  background-color: #fff3db !important;
}

.bg-gray-main {
  background-color: #c9c9c9 !important;
}

.bg-gray-light {
  background-color: #f8f8f8 !important;
}

.bg-gray-brown-light {
  background-color: #f8f3f0 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

/***************************************
    Layout
****************************************/
/***************************************
    Platform
****************************************/
/**
  * The split() method turns a String into an array of strings,
  * by separating the string at each instance of a specified separator string.
 */
/**
  * Add transition to mulitple properties at once.
  * @example
  * @include transition('transform, color', .3s);
 */
/**
 This is the order at which the elements will stack.
 List goes from lowest to highest.
 LOWEST -------------------> HIGHEST
 *Example:
 @include z-index('navigation');
 */
/**
 * Check if RTL is enabled.
 */
/**
 * Invert side of the margin.
 */
/**
 * If RTL Mode.
 */
/**
 * Reverse row for flex items
 */
/**
 * Add spacing to the right side
 */
/**
 * Add padding to the right side
 */
/**
	* Given a property flips its side for RLT.
	* @example
	*
	* @include flip(border-left, 1px solid red)
	* // => border-right: 1px solid red;
	*
	* @include flip(border-top-left-radius, 1px)
	* // => border-top-right-radius: 1px;
	*
	* @include flip(border-top, 1px solid red)
	* // => border-top: 1px solid red;
 */
.litepicker,
.litepicker[data-plugins*=multiselect] {
  margin: 8px 0;
  font-size: 16px;
  font-family: inherit;
}
.litepicker .container__months,
.litepicker[data-plugins*=multiselect] .container__months {
  border: 1px solid #1c5335;
  box-shadow: 3px 3px 16px rgba(0, 0, 0, 0.16);
  border-radius: 0;
  width: calc(var(--litepicker-month-width) + 24px * 2);
}
.litepicker .container__months .month-item-name,
.litepicker[data-plugins*=multiselect] .container__months .month-item-name {
  text-transform: capitalize;
}
.litepicker .container__months.columns-2,
.litepicker[data-plugins*=multiselect] .container__months.columns-2 {
  width: calc(var(--litepicker-month-width) * 2 + 24px * 4);
}
.litepicker .container__months .month-item-header,
.litepicker[data-plugins*=multiselect] .container__months .month-item-header {
  padding-top: 0;
}
.litepicker .container__months .month-item-weekdays-row > div,
.litepicker[data-plugins*=multiselect] .container__months .month-item-weekdays-row > div {
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--litepicker-day-width);
  font-size: 16px;
  font-weight: 400;
  text-transform: capitalize;
}
.litepicker .container__months .month-item,
.litepicker[data-plugins*=multiselect] .container__months .month-item {
  padding: 24px;
}
.litepicker .container__days .day-item.is-locked,
.litepicker[data-plugins*=multiselect] .container__days .day-item.is-locked {
  text-decoration: line-through;
}
.litepicker .container__days .day-item,
.litepicker[data-plugins*=multiselect] .container__days .day-item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--litepicker-day-width);
  height: var(--litepicker-day-width);
  margin: 4px 0;
  padding: 0;
  border-radius: 0;
}
.litepicker .container__days .day-item:hover,
.litepicker[data-plugins*=multiselect] .container__days .day-item:hover {
  cursor: pointer;
}
.litepicker .container__days .day-item.is-locked:hover,
.litepicker[data-plugins*=multiselect] .container__days .day-item.is-locked:hover {
  cursor: not-allowed;
}
.litepicker .container__days .day-item:not(.is-locked):hover,
.litepicker[data-plugins*=multiselect] .container__days .day-item:not(.is-locked):hover {
  font-weight: normal;
  color: var(--litepicker-day-today-color-hover);
}
.litepicker .container__days .day-item:not(.is-locked):hover::after,
.litepicker[data-plugins*=multiselect] .container__days .day-item:not(.is-locked):hover::after {
  border-radius: 0;
  box-shadow: inset 0 0 0 1px var(--litepicker-day-color-hover);
  width: var(--litepicker-multiselect-day-size-selected);
  height: var(--litepicker-multiselect-day-size-selected);
}
.litepicker .container__days .day-item.is-selected, .litepicker .container__days .day-item.is-start-date, .litepicker .container__days .day-item.is-end-date, .litepicker .container__days .day-item.is-start-date.is-end-date,
.litepicker[data-plugins*=multiselect] .container__days .day-item.is-selected,
.litepicker[data-plugins*=multiselect] .container__days .day-item.is-start-date,
.litepicker[data-plugins*=multiselect] .container__days .day-item.is-end-date,
.litepicker[data-plugins*=multiselect] .container__days .day-item.is-start-date.is-end-date {
  border-radius: 0;
}
.litepicker .container__days .day-item.is-selected.is-flipped, .litepicker .container__days .day-item.is-start-date.is-flipped, .litepicker .container__days .day-item.is-end-date.is-flipped, .litepicker .container__days .day-item.is-start-date.is-end-date.is-flipped,
.litepicker[data-plugins*=multiselect] .container__days .day-item.is-selected.is-flipped,
.litepicker[data-plugins*=multiselect] .container__days .day-item.is-start-date.is-flipped,
.litepicker[data-plugins*=multiselect] .container__days .day-item.is-end-date.is-flipped,
.litepicker[data-plugins*=multiselect] .container__days .day-item.is-start-date.is-end-date.is-flipped {
  border-radius: 0;
}
.litepicker .container__days .day-item.is-selected:hover, .litepicker .container__days .day-item.is-start-date:hover, .litepicker .container__days .day-item.is-end-date:hover, .litepicker .container__days .day-item.is-start-date.is-end-date:hover,
.litepicker[data-plugins*=multiselect] .container__days .day-item.is-selected:hover,
.litepicker[data-plugins*=multiselect] .container__days .day-item.is-start-date:hover,
.litepicker[data-plugins*=multiselect] .container__days .day-item.is-end-date:hover,
.litepicker[data-plugins*=multiselect] .container__days .day-item.is-start-date.is-end-date:hover {
  font-weight: normal;
  color: #fff;
}
.litepicker .container__days .day-item.is-selected::after, .litepicker .container__days .day-item.is-start-date::after, .litepicker .container__days .day-item.is-end-date::after, .litepicker .container__days .day-item.is-start-date.is-end-date::after,
.litepicker[data-plugins*=multiselect] .container__days .day-item.is-selected::after,
.litepicker[data-plugins*=multiselect] .container__days .day-item.is-start-date::after,
.litepicker[data-plugins*=multiselect] .container__days .day-item.is-end-date::after,
.litepicker[data-plugins*=multiselect] .container__days .day-item.is-start-date.is-end-date::after {
  border-radius: 0;
  width: var(--litepicker-multiselect-day-size-selected);
  height: var(--litepicker-multiselect-day-size-selected);
}

.litepicker[data-plugins*=multiselect] .day-item.is-selected:hover {
  background-color: var(--litepicker-highlighted-day-color-bg);
  color: var(--litepicker-highlighted-day-color);
}

.litepicker--inline .litepicker {
  margin: initial;
  z-index: 0 !important;
}
.litepicker--inline .litepicker .container__months {
  box-shadow: none;
  border: none;
  width: var(--litepicker-month-width);
}
.litepicker--inline .litepicker .container__months.columns-2 {
  width: calc(var(--litepicker-month-width) * 2 + 24px * 2);
}
.litepicker--inline .litepicker .container__months.columns-2 .month-item + .month-item {
  padding-left: 48px;
}
.litepicker--inline .litepicker .container__months .month-item {
  padding: 0;
}

.litepicker .container__months .month-item-header .button-previous-month,
.litepicker .container__months .month-item-header .button-next-month {
  cursor: pointer;
}

.responsive-menu .button__toggle-responsive-menu, .responsive-menu .logout-button, .site-menu__item a,
.site-menu__item button, .modal__close, .flash-message button, .w-remove-file button,
.w-remove-file a, .w-download-file button,
.w-download-file a, .button-underline-with-icon, .button__tertiary-red, .button--primary-inverted, .button-underline-with-icon .button-underline-with-icon__text, .button--underline, .button--gray, .button--primary, .button--reset {
  background: none;
  border: 0;
  margin: 0;
  cursor: pointer;
  outline: none;
  padding: 0;
  line-height: 1;
  color: inherit;
}

.heading-4, .heading-3, .heading-2, .heading-1, .heading-hero {
  font-weight: 400;
}

.heading-hero {
  font-size: 48px;
  line-height: 1;
}

.heading-1 {
  font-size: 34px;
}

.heading-2 {
  font-size: 24px;
}

.heading-3 {
  font-size: 20px;
}

.heading-4 {
  color: #e86825;
  font-size: 12px;
  letter-spacing: 3.2px;
  text-transform: uppercase;
}

@media (min-width: 1024px) {
  .heading-hero {
    font-size: 68px;
  }
  .heading-1 {
    font-size: 48px;
  }
  .heading-2 {
    font-size: 32px;
  }
  .heading-3 {
    font-size: 24px;
  }
  .heading-4 {
    font-size: 16px;
    letter-spacing: 4.5px;
  }
}
.display--none {
  display: none !important;
}

/**
  * The split() method turns a String into an array of strings,
  * by separating the string at each instance of a specified separator string.
 */
/**
  * Add transition to mulitple properties at once.
  * @example
  * @include transition('transform, color', .3s);
 */
/**
 This is the order at which the elements will stack.
 List goes from lowest to highest.
 LOWEST -------------------> HIGHEST
 *Example:
 @include z-index('navigation');
 */
/**
 * Check if RTL is enabled.
 */
/**
 * Invert side of the margin.
 */
/**
 * If RTL Mode.
 */
/**
 * Reverse row for flex items
 */
/**
 * Add spacing to the right side
 */
/**
 * Add padding to the right side
 */
/**
	* Given a property flips its side for RLT.
	* @example
	*
	* @include flip(border-left, 1px solid red)
	* // => border-right: 1px solid red;
	*
	* @include flip(border-top-left-radius, 1px)
	* // => border-top-right-radius: 1px;
	*
	* @include flip(border-top, 1px solid red)
	* // => border-top: 1px solid red;
 */
.dropdown {
  cursor: pointer;
  line-height: 1;
}

.dropdown-menu {
  display: none;
  position: absolute;
  border: 1px solid #1c5335;
  box-shadow: 3px 3px 16px rgba(0, 0, 0, 0.16);
  margin: 0;
  padding: 0;
  min-width: 160px;
  background: #ffffff;
  color: #1c5335;
  font-size: 16px;
  list-style: none;
  z-index: 1080;
}

.dropdown-menu.active {
  display: block;
}

.dropdown__indicator {
  transition: transform 0.2s ease-in-out;
}

.dropdown__indicator.active {
  transform: rotate(180deg);
}

.dropdown__item {
  display: flex;
  align-items: center;
  width: 100%;
  height: 48px;
  padding: 0 16px;
  transition-property: background-color, color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  color: #1c5335;
  font-size: 16px;
  white-space: nowrap;
  text-decoration: none;
  text-align: left;
}
.dropdown__item:hover {
  background-color: #1c5335;
  color: #fff;
  cursor: pointer;
}

@media (max-width: 767.98px) {
  .dropdown__indicator {
    display: none;
  }
  .dropdown--expend-tablet .dropdown__trigger {
    display: none;
  }
  .dropdown--expend-tablet .dropdown-menu {
    display: block;
    position: static;
    background: none;
    border: 0;
    box-shadow: none;
  }
  .dropdown--expend-tablet .dropdown__item {
    text-decoration: underline;
    padding: 0;
  }
  .dropdown--expend-tablet .dropdown__item:hover {
    background-color: transparent;
    color: #e86825;
  }
}
@media (max-width: 1023.98px) {
  .dropdown--expend-laptop .dropdown__trigger {
    display: none;
  }
  .dropdown--expend-laptop .dropdown-menu {
    display: block;
    position: static;
    background: none;
    border: 0;
    box-shadow: none;
  }
  .dropdown--expend-laptop .dropdown__item {
    text-decoration: underline;
    padding: 0;
  }
  .dropdown--expend-laptop .dropdown__item:hover {
    background-color: transparent;
    color: #e86825;
  }
}
.status-label {
  height: 24px;
  padding: 4px 8px;
  font-size: 12px;
  letter-spacing: 0;
  text-transform: capitalize;
}
.status-label--orange {
  color: #e86825;
  background-color: #ffebe1;
}
.status-label--green {
  color: #63b98a;
  background-color: #e7f2e3;
}
.status-label--red {
  color: #c84603;
  background-color: #ffe8e8;
}

.status-circle {
  display: inline-block;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: #c9c9c9;
}
.status-circle--red, .status-circle.missing {
  background-color: #c80303;
}
.status-circle--yellow, .status-circle.requested {
  background-color: #ffb31b;
}
.status-circle--green, .status-circle.received {
  background-color: #63b98a;
}

.status-circle-label {
  display: flex;
  align-items: center;
}
.status-circle-label .status-circle + * {
  margin-inline-start: 8px;
}

.flex {
  display: flex !important;
}

.flex-column {
  display: flex !important;
  flex-direction: column !important;
}

.flex-center {
  display: flex !important;
  align-items: center !important;
}

.flex-jc-center {
  display: flex !important;
  justify-content: center !important;
}

.jc-center {
  justify-content: center !important;
}

.flex--spread {
  justify-content: space-between !important;
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.flex--no-shrink {
  flex-shrink: 0;
}

.flex-1 {
  flex: 1 !important;
}

.flex-2 {
  flex: 2;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

@media (min-width: 768px) {
  .order-tablet-1 {
    order: 1;
  }
}
@media (min-width: 768px) {
  .order-tablet-2 {
    order: 2;
  }
}
@media (min-width: 768px) {
  .order-tablet-3 {
    order: 3;
  }
}
@media (min-width: 768px) {
  .order-tablet-4 {
    order: 4;
  }
}
* {
  box-sizing: border-box;
}

html,
body {
  -webkit-text-size-adjust: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  height: 100%;
  background-color: #ffffff;
  letter-spacing: -0.25px;
}

.header .logo {
  display: inline-flex;
  text-decoration: none;
}

.hidden,
*[hidden] {
  display: none !important;
}

.layout {
  display: flex;
  width: 100%;
  height: 100%;
}
@media (max-width: 1023px) {
  .layout {
    flex-direction: column;
  }
}
.layout .content {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  padding-bottom: 60px;
}

.dashboard-page {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.dashboard-page .page-content {
  padding: 24px 40px;
}
@media (max-width: 767px) {
  .dashboard-page .page-content {
    padding: 0 22px 20px;
  }
}

.responsive-menu .logout-button, .site-menu__item a,
.site-menu__item button, .two-column-section__subtitle, .two-column-section__title, .overview-wrapper .overview-box, .breadcrumbs ul, .page-title-section .buttons-wrapper, .page-title-section .title-wrapper a, .page-title-section h2, .page-title-section h1, .radio-checkbox-group__inline, .tabs a, .tabs ul {
  display: flex;
  align-items: center;
}

.overview-wrapper .overview-box .overview-box__content, .radio-checkbox-group__multiple-lines {
  display: flex;
  flex-direction: column;
}

.pagination__list, .breadcrumbs ul, .tabs ul {
  padding-left: 0;
  list-style: none;
  margin: 0;
}

.min-w-100 {
  min-width: 100px;
}

.min-w-125 {
  min-width: 125px;
}

.min-w-150 {
  min-width: 150px;
}

.min-w-175 {
  min-width: 175px;
}

.min-w-200 {
  min-width: 200px;
}

.min-w-225 {
  min-width: 225px;
}

.min-w-250 {
  min-width: 250px;
}

@font-face {
  font-family: "Euclid";
  src: url("../../../fonts/euclidCircularA/EuclidCircularA-Regular.woff2") format("woff2"), url("../../../fonts/euclidCircularA/EuclidCircularA-Regular.woff") format("woff");
  font-weight: 400;
}
@font-face {
  font-family: "Euclid";
  src: url("../../../fonts/euclidCircularA/EuclidCircularA-Semibold.woff2") format("woff2"), url("../../../fonts/euclidCircularA/EuclidCircularA-Semibold.woff") format("woff");
  font-weight: bold;
}
@font-face {
  font-family: "Euclid";
  src: url("../../../fonts/euclidCircularA/EuclidCircularA-Medium.woff2") format("woff2"), url("../../../fonts/euclidCircularA/EuclidCircularA-Medium.woff") format("woff");
  font-weight: 500;
}
/**
  Convert PX to REM based on global font size. | v1.0 | 2016
 */
body {
  font-family: "Euclid", sans-serif, "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 16px;
  color: #1c5335;
  line-height: 24px;
  margin: 0;
  -webkit-font-smoothing: antialiased;
}

a {
  color: #1c5335;
}

input,
select,
textarea,
button {
  font: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  margin: 0;
}

h1 {
  font-size: 40px;
  font-size: 2.5rem;
  line-height: 44px;
}

h2 {
  font-size: 36px;
  font-size: 2.25rem;
  line-height: 42px;
}

h3 {
  font-size: 28px;
  font-size: 1.75rem;
  line-height: 34px;
}

h4 {
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 26px;
}

h5 {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 24px;
}

p {
  margin: 0;
  font-size: 16px;
  font-size: 1rem;
  line-height: 22px;
  font-weight: 400;
}

.icon {
  fill: currentColor;
  width: 1em;
  height: 1em;
  vertical-align: middle;
  overflow: hidden;
}

.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  max-width: 440px;
  height: 46px;
  padding: 0 16px;
  font-size: 16px;
  font-size: 1rem;
  line-height: 24px;
  font-weight: 500;
  text-decoration: none;
  transition: background 0.3s ease-in-out;
}
.button:disabled, .button.disabled {
  opacity: 0.4;
  cursor: initial;
}
.button.large {
  width: 100%;
  max-width: 100%;
}
.button.large.margin-bottom {
  margin-bottom: 24px;
}

.button--primary {
  background: #e86825;
  color: #fff;
}
.button--primary:hover {
  background: #1c5335;
}
.button--primary:active {
  background: #23342f;
}

.button--gray {
  background: #f8f8f8;
  color: #000000;
}
.button--gray:hover {
  background: #ffebe1;
}
.button--gray:active {
  background: #ebebeb;
}

.button-underline-with-icon .button-underline-with-icon__text, .button--underline {
  border-radius: 0;
  border-bottom: 1px solid #1c5335;
  height: 33px;
  padding: 0 0 2px;
  color: #1c5335;
  font-size: 16px;
  line-height: 1.4;
  transition: border 0.2s ease-in-out;
}

.button--underline:hover, .button--underline:focus {
  border-bottom-color: #e86825;
  color: #e86825;
}
.button--underline:hover svg, .button--underline:focus svg {
  fill: #e86825;
}
.button--underline:active {
  color: #808080;
}
.button--underline:active svg {
  fill: #808080;
}
.button--underline[disabled], .button--underline.disabled {
  border-bottom-color: #6b8f7c;
  color: #6b8f7c;
}
.button--underline svg {
  margin-inline-end: 2px;
  fill: #5d7d6a;
  width: 10px;
  height: 10px;
}
.button--underline.button--small {
  height: 27px;
  font-size: 16px;
}
.button--underline-secondary, .button--underline-secondary:hover, .button--underline-secondary:focus {
  border-bottom-color: #e86825;
  color: #e86825;
}

.button--primary-inverted {
  background: #ffffff;
  color: #3a564f;
  border: 1px solid #3a564f;
}
.button--primary-inverted:hover {
  background: #e9ebea;
}
.button--primary-inverted:active {
  background: #e9ebea;
}

.button__tertiary-red {
  background: #ffffff;
  color: #f76363;
  border: 1px solid #f76363;
}
.button__tertiary-red:hover {
  background: #fddada;
}
.button__tertiary-red:active {
  background: #fddada;
}

.buttons-wrapper__in-row {
  margin-top: 24px;
}
.buttons-wrapper__in-row .button:last-of-type {
  margin-left: 16px;
}
.buttons-wrapper__in-row .button:first-child {
  margin-left: 0;
}

.margin {
  margin-bottom: 10px;
  margin-top: 24px;
}

/**
 * Underline button but with a span. Used for dropdown with chevron icon.
 */
.button-underline-with-icon:hover .button-underline-with-icon__text, .button-underline-with-icon:focus .button-underline-with-icon__text {
  border-bottom-color: #e86825;
  color: #1c5335;
}
.button-underline-with-icon[disabled] .button-underline-with-icon__text {
  border-bottom-color: #6b8f7c;
  color: #6b8f7c;
}
.button-underline-with-icon.button--small {
  height: auto;
  padding: 0;
}
.button-underline-with-icon.button--small .button-underline-with-icon__text {
  height: 27px;
  font-size: 16px;
}

.fields-wrapper__inline {
  display: flex;
  align-items: baseline;
  max-width: 440px;
  margin-top: 16px;
}
.fields-wrapper__inline .form-group {
  flex-grow: 1;
  margin-top: 0;
}
.fields-wrapper__inline .form-group + .form-group {
  margin-top: 0;
  margin-left: 24px;
}
.fields-wrapper__inline .add-more-list__button {
  margin-left: 12px;
}
@media (max-width: 767px) {
  .fields-wrapper__inline {
    flex-direction: column;
  }
  .fields-wrapper__inline .form-group {
    max-width: 100%;
  }
  .fields-wrapper__inline .form-group + .form-group {
    margin-top: 16px;
    margin-left: 0;
  }
  .fields-wrapper__inline .add-more-list__button {
    margin-top: 16px;
    margin-left: 0;
  }
}

.form-section {
  margin-top: 15px;
}
.form-section h4 {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 24px;
  font-weight: 500;
}

.form-group {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 16px;
}
@media (max-width: 1023px) {
  .form-group .list-item {
    display: flex;
  }
}
.form-group + .form-group {
  margin-top: 16px;
}
.form-group__select-wrapper {
  position: relative;
  max-width: 440px;
}
.form-group__select-wrapper:before {
  pointer-events: none;
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='%23e86825' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M.166 4.611L4.749.161a.578.578 0 01.8 0l.535.52a.538.538 0 01.001.775L2.453 5l3.632 3.544c.22.214.22.562-.001.776l-.535.52a.578.578 0 01-.8 0L.166 5.387a.538.538 0 010-.777z'/%3E%3C/svg%3E");
  position: absolute;
  right: 12px;
  top: 50%;
  width: 10px;
  height: 10px;
  transform: translateY(-50%) rotate(-90deg);
}
.form-group__select-wrapper option[disabled] {
  display: none;
}
.form-group input,
.form-group select {
  height: 40px;
}
.form-group input,
.form-group textarea,
.form-group select {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 27px;
  width: 100%;
  max-width: 440px;
  border: 1px solid #1c5335;
  padding: 6px 12px 6px 12px;
  background: none;
  color: #1c5335;
  font-weight: 500;
  outline: none;
  transition: all 0.3s ease;
}
.form-group input::placeholder,
.form-group textarea::placeholder,
.form-group select::placeholder {
  color: #6b8f7c;
}
.form-group input:hover,
.form-group textarea:hover,
.form-group select:hover {
  border: 1px solid #1c5335;
}
.form-group input:focus,
.form-group textarea:focus,
.form-group select:focus {
  border: 1px solid #1c5335;
}
.form-group input:disabled,
.form-group textarea:disabled,
.form-group select:disabled {
  color: #becac2;
  border: 1px solid #becac2;
  background-color: transparent;
}
.form-group .text-editor {
  width: 100%;
  max-width: 440px;
}
.form-group input[type=number] {
  -moz-appearance: textfield;
  appearance: textfield;
  margin: 0;
}
.form-group input[type=number]::-webkit-inner-spin-button, .form-group input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.form-group input[type=date]::-webkit-inner-spin-button,
.form-group input[type=time]::-webkit-inner-spin-button {
  display: none;
}
.form-group input[type=date]::-webkit-calendar-picker-indicator,
.form-group input[type=time]::-webkit-calendar-picker-indicator {
  opacity: 0;
}
.form-group textarea {
  display: block;
  resize: none;
  min-height: 130px;
}
.form-group select {
  -webkit-appearance: none;
  -moz-appearance: none;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 17px;
}
.form-group--error {
  text-align: left;
}
.form-group--error input,
.form-group--error textarea,
.form-group--error select {
  border-color: #e40000;
}
.form-group--error .input-error {
  color: #e40000;
  font-weight: 500;
  display: inline-block;
  margin-top: 4px;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 24px;
}

.form-label {
  color: #1c5335;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 24px;
  display: inline-flex;
  margin-bottom: 4px;
  font-weight: 500;
}
.form-label.required:after {
  content: "*";
  color: #e40000;
  margin-left: 5px;
}

span.info-message {
  display: inline-block;
  margin-top: 6px;
  font-size: 13px;
  font-size: 0.8125rem;
  line-height: 19px;
}

.form-group__select-wrapper svg {
  fill: #e86825;
  cursor: pointer;
}
.form-group__select-wrapper svg:hover {
  fill: #1c5335;
}

.w-download-file {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 16px;
  padding: 5px 16px;
  max-width: 440px;
  width: 100%;
  height: 56px;
  background-color: #f8f8f8;
  font-weight: 500;
  text-decoration: none;
  cursor: initial;
  transition: 0.3s background-color ease-in-out;
}
.w-download-file:hover {
  background-color: #ffebe1;
}
.w-download-file svg {
  width: 20px;
  height: 20px;
}
.w-download-file svg:first-child {
  margin-right: 10px;
}
.w-download-file .download-icon {
  fill: #1c5335;
}
.w-download-file button,
.w-download-file a {
  cursor: pointer;
  margin-left: auto;
}
.w-download-file button svg:first-child,
.w-download-file a svg:first-child {
  margin-right: 0;
}

.w-remove-file {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 16px;
  padding: 5px 16px;
  max-width: 440px;
  width: 100%;
  height: 56px;
  background-color: #f8f8f8;
  font-weight: 500;
  text-decoration: none;
  cursor: initial;
  transition: 0.3s background-color ease-in-out;
}
.w-remove-file:hover .remove-icon {
  fill: #1c5335;
}
.w-remove-file svg {
  width: 20px;
  height: 20px;
}
.w-remove-file svg:first-child {
  margin-right: 10px;
}
.w-remove-file .remove-icon {
  fill: #1c5335;
}
.w-remove-file button,
.w-remove-file a {
  cursor: pointer;
  margin-left: auto;
}
.w-remove-file button svg:first-child,
.w-remove-file a svg:first-child {
  margin-right: 0;
}

.tabs {
  margin-top: 32px;
  margin-bottom: 32px;
}
.tabs ul {
  border-bottom: 1px solid #d4ddd8;
}
@media (max-width: 567px) {
  .tabs ul {
    overflow-x: auto;
  }
  .tabs ul li {
    flex: 1 0 auto;
  }
}
@media (max-width: 767px) {
  .tabs ul {
    margin-left: -22px;
    margin-right: -22px;
    padding-right: 22px;
    padding-left: 22px;
  }
}
.tabs li + li {
  margin-left: 24px;
}
.tabs a {
  padding-bottom: 13px;
  border-bottom: 2px solid transparent;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease-in-out, border-bottom-color 0.3s ease-in-out;
}
.tabs a.active {
  border-bottom-color: #e86825;
}
.tabs a:hover {
  border-bottom-color: #e86825;
}

/*** Checkbox style ***/
.control {
  display: block;
  position: relative;
  padding-left: 34px;
  cursor: pointer;
  font-size: 15px;
  font-size: 0.9375rem;
  line-height: 23px;
  color: #1c5335;
}
.control input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.control input:focus ~ .control__indicator {
  background: none;
}
.control input:checked ~ .control__indicator {
  background: #fff;
}
.control input:checked ~ .control__indicator:after {
  display: block;
}

.control__indicator {
  margin-top: 0 !important;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  height: 24px;
  width: 24px;
  background: none;
  border: 1px solid #1c5335;
}
.control__indicator:after {
  content: "";
  position: absolute;
  display: none;
}

.control--checkbox .control__indicator:after {
  left: 7px;
  top: 1px;
  width: 6px;
  height: 14px;
  border: solid #e86825;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.deselect.control--checkbox {
  position: absolute;
  bottom: 70px;
  margin: 0px 16px;
}
.deselect.control--checkbox.control--checkbox .control__indicator:after {
  left: 6px;
  top: 3px;
  height: 6px;
  width: 0;
  border: 1px solid #fff;
  transform: rotate(90deg);
}

/*** Radio button style ***/
.control--radio .control__indicator {
  border-radius: 50%;
}
.control--radio .control__indicator:after {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 8px;
  height: 8px;
  border: 0;
}
.control--radio input:checked ~ .control__indicator {
  border-color: #fff;
  border-radius: 50%;
  background-color: #fff;
}
.control--radio input:checked ~ .control__indicator:after {
  background-color: #fff;
  border-radius: 50%;
}

.radio-checkbox-group__inline {
  margin-top: 16px;
}
.radio-checkbox-group__inline .control + .control {
  margin-left: 16px;
}
@media (max-width: 767px) {
  .radio-checkbox-group__inline {
    flex-direction: column;
    align-items: flex-start !important;
  }
  .radio-checkbox-group__inline .control + .control {
    margin-left: 0;
    margin-top: 16px;
  }
}
.radio-checkbox-group__multiple-lines .control + .control {
  margin-top: 16px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.switch input:checked + .control__indicator-switch {
  background-color: #1c5335;
}
.switch input:checked + .control__indicator-switch:before {
  transform: translateX(16px);
}
.switch .control__indicator-switch {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #d0d6d5;
  transition: background 0.3s ease;
  border-radius: 34px;
}
.switch .control__indicator-switch:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: background 0.3s ease;
  border-radius: 50%;
}

.label-item {
  padding: 4px 12px;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 24px;
  font-weight: 500;
  border-radius: 50px;
  border: 1px solid transparent;
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
}
.label-item.label-inactive {
  border-color: #e40000;
  color: #e40000;
  background-color: #fff;
}
.label-item.label-completed {
  color: #ffffff;
  background-color: #57a88a;
}

.page-title-section {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 40px;
  background-color: #fff;
}
@media (max-width: 567px) {
  .page-title-section {
    flex-direction: column;
    align-items: flex-start;
  }
}
.page-title-section-sticky {
  position: sticky;
  padding: 15px 0;
  top: 0;
  z-index: 100;
}
.page-title-section h1 {
  margin: 0;
  font-size: 32px;
  font-size: 2rem;
  line-height: 48px;
  font-weight: 500;
}
.page-title-section h2 {
  margin: 0;
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 32px;
  font-weight: 500;
}
@media (max-width: 567px) {
  .page-title-section h2 {
    margin-bottom: 12px;
  }
}
.page-title-section .title-wrapper {
  margin-inline-end: 20px;
}
.page-title-section .title-wrapper a {
  text-decoration: none;
  transition: color 0.3s ease-in-out, fill 0.3s ease-in-out;
  color: #626d83;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 18px;
}
.page-title-section .title-wrapper a:hover {
  color: #7986a1;
}
.page-title-section .title-wrapper a:hover svg {
  fill: #7986a1;
}
.page-title-section .title-wrapper a svg {
  fill: #626d83;
  margin-right: 4px;
}
.page-title-section .title-wrapper > a {
  margin-bottom: 10px;
}
.page-title-section .title-wrapper svg {
  width: 10px;
  height: 10px;
}
.page-title-section .title-wrapper--status {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.page-title-section .buttons-wrapper {
  flex-wrap: wrap;
}
.page-title-section .buttons-wrapper .button {
  display: inline-flex;
  margin-top: 20px;
  margin-right: 12px;
}
.page-title-section .buttons-wrapper .button:last-child {
  margin-right: 0;
}

.breadcrumbs {
  margin-bottom: 10px;
}
.breadcrumbs ul li + li {
  margin-left: 5px;
  position: relative;
  display: inline-flex;
  align-items: center;
}
.breadcrumbs ul li + li:before {
  content: "/";
  padding-right: 5px;
  color: #626d83;
}
.breadcrumbs ul a {
  margin-bottom: 0;
}

.subtitle-wrapper {
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
}
@media (max-width: 567px) {
  .subtitle-wrapper {
    flex-direction: column;
  }
}
.subtitle-wrapper.no-margin {
  margin: 0;
}
.subtitle-wrapper h3 {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 24px;
  color: #00050f;
  font-weight: bold;
}
.subtitle-wrapper h4 {
  font-size: 16px;
  font-size: 1rem;
  line-height: 21px;
  color: #00050f;
  font-weight: 500;
}
.subtitle-wrapper .button {
  margin-left: auto;
  display: inline-flex;
  text-decoration: none;
}
.subtitle-wrapper .single-info-item {
  margin-left: auto;
  margin-bottom: 0;
  font-weight: 500;
}
.subtitle-wrapper.subtitle-with-text {
  flex-direction: column;
  max-width: 360px;
}
.subtitle-wrapper.subtitle-with-text h3 {
  margin-bottom: 4px;
}
.subtitle-wrapper.subtitle-with-text p {
  color: #5d7d6a;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 20px;
}
.subtitle-wrapper.subtitle-with-author {
  margin-bottom: 0;
}
.subtitle-wrapper .buttons-wrapper {
  margin-left: auto;
}
@media (max-width: 567px) {
  .subtitle-wrapper .buttons-wrapper {
    margin-left: 0;
    margin-top: 16px;
    margin-bottom: 24px;
    width: 100%;
  }
  .subtitle-wrapper .buttons-wrapper .button {
    width: 100%;
    max-width: 100%;
  }
  .subtitle-wrapper .buttons-wrapper .button + .button {
    margin-top: 16px;
  }
}

.page-subtitle-section {
  margin-bottom: 24px;
}
.page-subtitle-section.no-margin {
  margin: 0;
}
.page-subtitle-section .empty {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 18px;
  color: #5d7d6a;
}
.page-subtitle-section table {
  border-collapse: collapse;
}
.page-subtitle-section table td {
  border-bottom: 1px solid #d4ddd8;
  padding: 8px 0;
}
.page-subtitle-section table td:first-child {
  padding-right: 32px;
}
.page-subtitle-section table tr:last-child td {
  border-bottom: none;
}

.single-info-item {
  margin-bottom: 16px;
  max-width: 800px;
}
.single-info-item h4 {
  margin-top: 0;
  margin-bottom: 8px;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 24px;
  text-transform: uppercase;
  color: #e86825;
  font-weight: 500;
  letter-spacing: 1px;
}
.single-info-item p {
  margin: 0;
  color: #1c5335;
  font-size: 16px;
  font-size: 1rem;
  line-height: 24px;
}
.single-info-item p.not-available {
  color: #afafaf;
}
.single-info-item ul,
.single-info-item ol {
  margin: 0;
  padding: 0 24px;
}
.single-info-item .editor-html h4 {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 28px;
  letter-spacing: 0.5px;
  text-transform: none;
  color: #e86825;
}
.single-info-item .editor-html p,
.single-info-item .editor-html ul,
.single-info-item .editor-html ol {
  margin-bottom: 8px;
}
.single-info-item .editor-html a {
  color: #1c5335;
}

img {
  max-width: 344px;
}

.items-wrapper__inline {
  display: flex;
  gap: 16px;
}

.circle-chart {
  overflow: visible;
}
.circle-chart__circle {
  animation: circle-chart-fill 2s reverse; /* 1 */
  transform: rotate(-90deg); /* 2, 3 */
  transform-origin: center; /* 4 */
}
.circle-chart__circle.circle-orange {
  stroke: #e40000;
}
.circle-chart__circle.circle-yellow {
  stroke: #f7d663;
}
.circle-chart__circle.circle-green {
  stroke: #3a564f;
}
.circle-chart__background {
  stroke: rgb(245, 246, 240);
}
.circle-chart__info {
  font-weight: bold;
  fill: #000000;
  color: #000;
}
.circle-chart__number {
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 16px;
}
.circle-chart__unit {
  font-size: 6px;
  font-weight: 400;
}
.circle-chart__wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.flash-message {
  border-radius: 4px;
  padding: 16px;
  display: flex;
  align-items: flex-start;
  max-width: 440px;
  margin-bottom: 16px;
  width: 100%;
  border: 1px solid;
  color: #fff;
  position: fixed;
  top: 24px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1001;
}
.flash-message__error h3, .flash-message__success h3, .flash-message__warning h3 {
  color: #ffffff;
}
.flash-message__error p, .flash-message__success p, .flash-message__warning p {
  color: #ffffff;
}
.flash-message__error button svg, .flash-message__success button svg, .flash-message__warning button svg {
  fill: #ffffff;
}
.flash-message__error {
  background-color: #f76363;
}
.flash-message__success {
  background-color: #57a88a;
}
.flash-message__warning {
  background-color: #f8a116;
}
.flash-message__content {
  margin-left: 12px;
  margin-right: auto;
}
.flash-message__content h3 {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 18px;
  font-weight: bold;
  margin-bottom: 3px;
}
.flash-message__content p {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 20px;
  font-weight: 400;
}
.flash-message__icon {
  position: relative;
  top: 3px;
  flex: none;
}
.flash-message.hide {
  display: none;
}
.flash-message button {
  display: inline-flex;
  position: relative;
  top: 6px;
}
.flash-message button svg {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}
.flash-message button:hover svg {
  opacity: 0.7;
}

.table {
  overflow-x: auto;
  width: 100%;
  border-collapse: collapse;
}
.table tr .arrow-up:after,
.table tr .arrow-down:after {
  content: "";
  display: inline-block;
  margin-left: 10px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
}
.table tr .arrow-up:after {
  border-bottom: 12px solid #1c5335;
}
.table tr .arrow-down:after {
  border-top: 12px solid #1c5335;
}
.table tr th {
  padding: 16px;
  background-color: #f8f8f8;
  color: #1c5335;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 24px;
  text-align: left;
  font-weight: 500;
  font-variant: tabular-nums;
}
.table tr th.small-width {
  width: 150px !important;
}
.table tr th a {
  display: inline;
  letter-spacing: 0.25px;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 24px;
  text-decoration: none;
}
.table tr td {
  border-bottom: 1px solid #d4ddd8;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 24px;
  padding: 16px;
  font-variant: tabular-nums;
}
.table tr td a:not(.button) {
  text-decoration: none;
}
.table tr td a:not(.button):hover {
  text-decoration: underline;
}
.table tr td.primary {
  color: #1c5335;
  font-weight: 500;
}
.table tr td .changed-booking {
  display: grid;
  grid-template-columns: 1fr min-content;
  align-items: center;
}
.table tr td .changed-booking .icon {
  color: #ffb31b;
}
.table .button-underline-with-icon .button-underline-with-icon__text {
  color: #1c5335;
  border-color: #1c5335;
  font-size: 14px;
}

.table-section {
  margin-top: 3em;
}
.table-section__header {
  margin-bottom: 0.5em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table-wrapper {
  overflow-x: auto;
}
.table-wrapper .invoice-table .w-15 {
  width: 15%;
}
.table-wrapper .invoice-table .w-5 {
  width: 5%;
}
.table-wrapper .invoice-table .w-auto {
  width: auto;
}
.table-wrapper .invoice-table .w-auto .form-group input,
.table-wrapper .invoice-table .w-auto .form-group textarea {
  max-width: 100%;
}

.table-info {
  margin: 1em 0;
  font-size: 14px;
}

.align-right {
  text-align: right !important;
}

.align-top {
  vertical-align: top;
}

.context {
  cursor: help;
}

.table-search-section {
  margin-bottom: 24px;
}
@media (min-width: 480px) {
  .table-search-section {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
.table-search-section .dropdown {
  margin-bottom: 15px;
}
@media (min-width: 480px) {
  .table-search-section .dropdown {
    margin-bottom: 0;
  }
}
.table-search-section .input {
  background: none;
  border: 0;
  border-radius: 0;
  outline: 0;
  font: inherit;
  color: #1c5335;
  width: 100%;
}
.table-search-section .input--primary {
  border: 1px solid #1c5335;
  height: 48px;
  padding: 0 16px;
}
.table-search-section .input-with-icon {
  position: relative;
  max-width: 350px;
}
@media (min-width: 480px) {
  .table-search-section .input-with-icon {
    margin-left: auto;
    min-width: 290px;
  }
}
.table-search-section .input-with-icon:before {
  background-color: #1c5335;
  bottom: 0;
  content: "";
  height: 48px;
  left: auto;
  pointer-events: none;
  position: absolute;
  right: 0;
  width: 48px;
}
.table-search-section .input-with-icon .input {
  padding-inline-end: 64px;
}
.table-search-section .input-with-icon button.input-with-icon__suffix {
  height: 48px;
  right: 0;
  top: 0;
  transform: none;
  width: 48px;
  color: #fff;
  font-size: 20px;
  left: auto;
  position: absolute;
}
.table-search-section .icon {
  fill: currentColor;
  height: 1em;
  overflow: hidden;
  pointer-events: none;
  vertical-align: middle;
  width: 1em;
}
.table-search-section .button--reset {
  background: none;
  border: 0;
  color: inherit;
  cursor: pointer;
  line-height: 1;
  outline: none;
  padding: 0;
}

.table-sorter {
  position: relative;
}
.table-sorter:after, .table-sorter:before {
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  right: -15px;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  transform: translateY(50%);
}
.table-sorter:before {
  border-top: 4px solid #9396af;
  top: 50%;
}
.table-sorter:after {
  border-bottom: 4px solid #9396af;
  border-top: 4px solid transparent;
  bottom: calc(50% + 5px);
}

.asc.table-sorter:before,
.desc.table-sorter:after {
  opacity: 0;
}

.modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1070;
}

.modal__container {
  position: relative;
  max-height: 100%;
  background-color: #fff;
  padding: 30px 24px;
  max-width: 640px;
  width: 100%;
  overflow-y: auto;
  box-sizing: border-box;
}

.modal__header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 16px;
}

.modal__title {
  margin: 0;
}

.modal__close {
  margin-inline-start: 32px;
  font-size: 32px;
  line-height: 0;
}

.modal__footer {
  padding: 0;
  margin-top: 64px;
}

.modal__action {
  justify-content: center;
  min-width: 168px;
}

.modal--xl .modal__container {
  max-width: 988px;
}

@media (min-width: 768px) {
  .modal__body {
    width: 80%;
  }
}
@media (min-width: 1024px) {
  .modal__container {
    padding: 64px 32px;
  }
  .modal__content {
    padding-right: 64px;
  }
  .modal--sm .modal__container {
    padding: 64px;
  }
}
/**************************\
  Animations
\**************************/
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.modal {
  display: none;
}

.modal.is-open {
  display: block;
}

.modal[aria-hidden=false] .modal__overlay {
  animation: fadeIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.modal[aria-hidden=false] .modal__container {
  animation: fadeIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.modal[aria-hidden=true] .modal__overlay {
  animation: fadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.modal[aria-hidden=true] .modal__container {
  animation: fadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.modal .modal__container,
.modal .modal__overlay {
  will-change: transform;
}

.upload-file {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 440px;
  max-width: 100%;
  height: 56px;
  cursor: pointer;
  color: #1c5335;
  border: 1px dashed #becac2;
}
.upload-file input[type=file] {
  visibility: hidden;
  width: 0;
  height: 0;
  position: absolute;
}
.upload-file--image .upload-file__create {
  display: block;
  text-align: center;
  width: 100%;
}
.upload-file--image .upload-file__preview {
  background-color: transparent;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: none;
  width: 100%;
  height: 100%;
  flex-shrink: 0;
}
.upload-file--image .upload-file__edit {
  display: none;
  position: absolute;
  bottom: 12px;
  right: 12px;
  line-height: 24px;
  font-size: 1rem;
}
.upload-file--uploaded {
  border: 1px solid #becac2;
}
.upload-file--uploaded .upload-file__create {
  display: none;
}
.upload-file--uploaded .upload-file__edit {
  display: block;
}
.upload-file--uploaded .upload-file__preview {
  display: block;
  transform: rotate(0);
}
.upload-file--uploaded svg {
  width: 24px;
  height: 24px;
}

.overview-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 16px;
  grid-auto-rows: 1fr;
  margin-bottom: 32px;
}
@media (max-width: 1023px) {
  .overview-wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 767px) {
  .overview-wrapper {
    grid-template-columns: repeat(1, 1fr);
  }
}
.overview-wrapper .overview-box {
  max-width: 100%;
  border: 1px solid #e6e8eb;
  border-radius: 4px;
  padding: 20px 16px;
}
.overview-wrapper .overview-box .overview-box__content {
  margin-left: 16px;
  justify-content: space-between;
}
.overview-wrapper .overview-box .overview-box__content h4 {
  font-size: 22px;
  font-size: 1.375rem;
  line-height: 30px;
  font-weight: 700;
  color: #282828;
}
.overview-wrapper .overview-box .overview-box__content p {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 19px;
  color: #1c5335;
}
.overview-wrapper .overview-box svg {
  width: 48px;
  height: 48px;
}

.two-column-section__title {
  margin: 0 0 24px 0;
  padding-bottom: 8px;
  border-bottom: 1px solid #d4ddd8;
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 32px;
  font-weight: 500;
}
.two-column-section__subtitle {
  margin: 0 0 12px 0;
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 32px;
  font-weight: 500;
}
.two-column-section__title + .two-column-section__subtitle,
.two-column-section .two-column-wrapper + .two-column-section__subtitle {
  margin-top: 32px;
}
.two-column-section .two-column-wrapper {
  margin-bottom: 24px;
  display: flex;
  justify-content: flex-start;
}
@media (max-width: 767px) {
  .two-column-section .two-column-wrapper {
    flex-direction: column;
  }
}
.two-column-section .two-column-wrapper .form-group {
  margin: 0;
  flex-basis: 50%;
  justify-self: flex-end;
}
.two-column-section .two-column-wrapper .form-group input,
.two-column-section .two-column-wrapper .form-group select,
.two-column-section .two-column-wrapper .form-group textarea,
.two-column-section .two-column-wrapper .form-group .text-editor {
  max-width: 100%;
}
.two-column-section .two-column-wrapper .single-info-item {
  padding-right: 48px;
  flex-basis: 50%;
}

.side-menubar {
  background: #1c5335;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 100%;
  max-width: 240px;
}
@media (max-width: 1023px) {
  .side-menubar {
    display: none;
  }
}
.side-menubar h2 {
  transition: opacity 0.3s ease-in-out;
  transition-delay: 0.3s;
}
.side-menubar .header {
  padding: 24px 24px 0 24px;
}
.side-menubar .logo {
  position: relative;
  transform: translateX(0px);
  transition: all 0.3s ease-in-out;
}
.side-menubar .logo img {
  width: 100%;
}
.side-menubar h2 {
  text-transform: uppercase;
  font-size: 11px;
  font-size: 0.6875rem;
  line-height: 14px;
  color: #5d7d6a;
  padding: 24px 24px 0;
  min-height: 56px;
  font-weight: bold;
  letter-spacing: 0.25px;
}
.side-menubar.responsive h2,
.side-menubar.responsive .site-menu__item span,
.side-menubar.responsive .profile-dropdown h3,
.side-menubar.responsive .profile-dropdown .dropdown-icon-arrow {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
.side-menubar.responsive .site-menu__item span {
  transition-delay: 0s;
}
.side-menubar.responsive .logo {
  opacity: 0;
  transform: translateX(-65px);
}
.side-menubar.responsive .button__toggle-sidemenu {
  transform: translateX(-38px) rotate(180deg);
}
.side-menubar.responsive .profile > div:first-child {
  width: auto;
}
@media (max-width: 1023px) {
  .side-menubar {
    transition: max-width 0.3s ease-in-out;
  }
}
.side-menubar.responsive {
  max-width: 44px;
}
.side-menubar.responsive .site-menu a {
  padding: 0 10px;
}

.site-menu {
  list-style: none;
  padding: 24px 0 12px;
  overflow-y: auto;
}
.site-menu__item a,
.site-menu__item button {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 19px;
  width: 100%;
  color: #fff;
  padding: 0 21px;
  min-height: 56px;
  font-weight: 500;
  border-left: 3px solid transparent;
  text-decoration: none;
}
.site-menu__item a.active,
.site-menu__item button.active {
  border-left-color: #ffebe1;
  color: #fff;
}
.site-menu__item a.active svg,
.site-menu__item button.active svg {
  fill: #ffebe1;
}
.site-menu__item a:hover,
.site-menu__item button:hover {
  background-color: rgba(235, 235, 235, 0.3);
}
.site-menu__item a svg,
.site-menu__item button svg {
  fill: #5d7d6a;
  transition: fill 0.4s ease-in-out;
  flex-shrink: 0;
}

.responsive-menu {
  display: none;
}
.responsive-menu__wrapper {
  display: none;
}
.responsive-menu__wrapper form {
  border-top: 1px solid #e6e8eb;
  margin: 0 22px;
}
.responsive-menu .site-menu__item a {
  padding: 0 21px;
  color: #1c5335;
}
.responsive-menu .site-menu__item a.active {
  color: #e86825;
  border-left-color: #e86825;
}
.responsive-menu .logout-button {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 19px;
  width: 100%;
  color: #1c5335;
  min-height: 56px;
  font-weight: 500;
  border-left: 3px solid transparent;
  transition: background-color 0.3s ease-in-out, padding 0.3s ease-in-out;
}
.responsive-menu .logout-button:hover {
  background-color: rgba(235, 235, 235, 0.3);
}
.responsive-menu .icon {
  width: 20px;
  height: 20px;
}
.responsive-menu .logo .icon {
  fill: #e86825;
  width: 32px;
  height: 32px;
}
@media (max-width: 1023px) {
  .responsive-menu {
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    padding: 12px 22px;
    border-bottom: 1px solid #e6e8eb;
    z-index: 1000;
  }
}
.responsive-menu .button__toggle-responsive-menu {
  margin-right: auto;
  display: flex;
  z-index: 10;
}
.responsive-menu .button__toggle-responsive-menu svg {
  fill: #1c5335;
}
.responsive-menu .icon-close {
  display: none;
}
.responsive-menu.responsive .responsive-menu__wrapper {
  display: block;
  position: absolute;
  background-color: #fff;
  width: 100%;
  height: 100%;
  z-index: 1;
  left: 0;
  top: 0;
  padding-top: 60px;
}
.responsive-menu.responsive .icon-close {
  display: block;
}
.responsive-menu.responsive .icon-menu {
  display: none;
}

.profile {
  flex: 1;
  display: flex;
  align-items: flex-end;
  margin-top: 0;
  margin-bottom: 24px;
}
.profile .site-menu {
  flex: 1;
  padding: 0;
  margin: 0;
}

body {
  margin: 0;
}

.auth {
  background: #e5e5e5;
  padding-top: 128px;
}
.auth__container {
  box-shadow: 0 1px 4px 0 rgba(21, 34, 50, 0.08);
  background: #ffffff;
  padding: 32px;
  width: 100%;
  max-width: 416px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  text-align: center;
}
.auth__container h3 {
  font-weight: 500;
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 32px;
  color: #1c5335;
  margin: 16px 0 5px;
}
.auth__container p {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 18px;
  color: #5d7d6a;
  text-align: center;
  margin-top: 5px;
}
.auth form {
  width: 100%;
  max-width: 352px;
  margin-top: 27px;
}
.auth form button {
  margin-top: 24px;
  margin-bottom: 32px;
  width: 100%;
}
.auth form input {
  max-width: 352px;
}
.auth .success-reset-password {
  width: 100%;
  max-width: 295px;
  text-align: center;
  margin: auto;
}
.auth .success-reset-password h4 {
  font-weight: bold;
  margin-top: 32px;
  color: #ffffff;
}

.keep-me-login-label {
  text-align: left;
}

/* Set a $size margin to all sides at $breakpoint */
.m-0 {
  margin: 0 !important;
}

.p-0 {
  padding: 0 !important;
}

/* Set a $size margin on the top at $breakpoint */
.mt-0 {
  margin-top: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

/* Set a $size margin on the right at $breakpoint */
.mr-0 {
  margin-right: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

/* Set a $size margin on the bottom at $breakpoint */
.mb-0 {
  margin-bottom: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

/* Set a $size margin on the left at $breakpoint */
.ml-0 {
  margin-left: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

/* Set a $size margin on the left & right at $breakpoint */
.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

/* Set a $size margin on the top & bottom at $breakpoint */
.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

/* Set a $size margin to all sides at $breakpoint */
.m-2 {
  margin: 2px !important;
}

.p-2 {
  padding: 2px !important;
}

/* Set a $size margin on the top at $breakpoint */
.mt-2 {
  margin-top: 2px !important;
}

.pt-2 {
  padding-top: 2px !important;
}

/* Set a $size margin on the right at $breakpoint */
.mr-2 {
  margin-right: 2px !important;
}

.pr-2 {
  padding-right: 2px !important;
}

/* Set a $size margin on the bottom at $breakpoint */
.mb-2 {
  margin-bottom: 2px !important;
}

.pb-2 {
  padding-bottom: 2px !important;
}

/* Set a $size margin on the left at $breakpoint */
.ml-2 {
  margin-left: 2px !important;
}

.pl-2 {
  padding-left: 2px !important;
}

/* Set a negative $size margin on top at $breakpoint */
.mt-n2 {
  margin-top: -2px !important;
}

/* Set a negative $size margin on the right at $breakpoint */
.mr-n2 {
  margin-right: -2px !important;
}

/* Set a negative $size margin on the bottom at $breakpoint */
.mb-n2 {
  margin-bottom: -2px !important;
}

/* Set a negative $size margin on the left at $breakpoint */
.ml-n2 {
  margin-left: -2px !important;
}

/* Set a $size margin on the left & right at $breakpoint */
.mx-2 {
  margin-right: 2px !important;
  margin-left: 2px !important;
}

/* Set a $size margin on the top & bottom at $breakpoint */
.my-2 {
  margin-top: 2px !important;
  margin-bottom: 2px !important;
}

/* Set a $size margin to all sides at $breakpoint */
.m-4 {
  margin: 4px !important;
}

.p-4 {
  padding: 4px !important;
}

/* Set a $size margin on the top at $breakpoint */
.mt-4 {
  margin-top: 4px !important;
}

.pt-4 {
  padding-top: 4px !important;
}

/* Set a $size margin on the right at $breakpoint */
.mr-4 {
  margin-right: 4px !important;
}

.pr-4 {
  padding-right: 4px !important;
}

/* Set a $size margin on the bottom at $breakpoint */
.mb-4 {
  margin-bottom: 4px !important;
}

.pb-4 {
  padding-bottom: 4px !important;
}

/* Set a $size margin on the left at $breakpoint */
.ml-4 {
  margin-left: 4px !important;
}

.pl-4 {
  padding-left: 4px !important;
}

/* Set a negative $size margin on top at $breakpoint */
.mt-n4 {
  margin-top: -4px !important;
}

/* Set a negative $size margin on the right at $breakpoint */
.mr-n4 {
  margin-right: -4px !important;
}

/* Set a negative $size margin on the bottom at $breakpoint */
.mb-n4 {
  margin-bottom: -4px !important;
}

/* Set a negative $size margin on the left at $breakpoint */
.ml-n4 {
  margin-left: -4px !important;
}

/* Set a $size margin on the left & right at $breakpoint */
.mx-4 {
  margin-right: 4px !important;
  margin-left: 4px !important;
}

/* Set a $size margin on the top & bottom at $breakpoint */
.my-4 {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}

/* Set a $size margin to all sides at $breakpoint */
.m-8 {
  margin: 8px !important;
}

.p-8 {
  padding: 8px !important;
}

/* Set a $size margin on the top at $breakpoint */
.mt-8 {
  margin-top: 8px !important;
}

.pt-8 {
  padding-top: 8px !important;
}

/* Set a $size margin on the right at $breakpoint */
.mr-8 {
  margin-right: 8px !important;
}

.pr-8 {
  padding-right: 8px !important;
}

/* Set a $size margin on the bottom at $breakpoint */
.mb-8 {
  margin-bottom: 8px !important;
}

.pb-8 {
  padding-bottom: 8px !important;
}

/* Set a $size margin on the left at $breakpoint */
.ml-8 {
  margin-left: 8px !important;
}

.pl-8 {
  padding-left: 8px !important;
}

/* Set a negative $size margin on top at $breakpoint */
.mt-n8 {
  margin-top: -8px !important;
}

/* Set a negative $size margin on the right at $breakpoint */
.mr-n8 {
  margin-right: -8px !important;
}

/* Set a negative $size margin on the bottom at $breakpoint */
.mb-n8 {
  margin-bottom: -8px !important;
}

/* Set a negative $size margin on the left at $breakpoint */
.ml-n8 {
  margin-left: -8px !important;
}

/* Set a $size margin on the left & right at $breakpoint */
.mx-8 {
  margin-right: 8px !important;
  margin-left: 8px !important;
}

/* Set a $size margin on the top & bottom at $breakpoint */
.my-8 {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

/* Set a $size margin to all sides at $breakpoint */
.m-12 {
  margin: 12px !important;
}

.p-12 {
  padding: 12px !important;
}

/* Set a $size margin on the top at $breakpoint */
.mt-12 {
  margin-top: 12px !important;
}

.pt-12 {
  padding-top: 12px !important;
}

/* Set a $size margin on the right at $breakpoint */
.mr-12 {
  margin-right: 12px !important;
}

.pr-12 {
  padding-right: 12px !important;
}

/* Set a $size margin on the bottom at $breakpoint */
.mb-12 {
  margin-bottom: 12px !important;
}

.pb-12 {
  padding-bottom: 12px !important;
}

/* Set a $size margin on the left at $breakpoint */
.ml-12 {
  margin-left: 12px !important;
}

.pl-12 {
  padding-left: 12px !important;
}

/* Set a negative $size margin on top at $breakpoint */
.mt-n12 {
  margin-top: -12px !important;
}

/* Set a negative $size margin on the right at $breakpoint */
.mr-n12 {
  margin-right: -12px !important;
}

/* Set a negative $size margin on the bottom at $breakpoint */
.mb-n12 {
  margin-bottom: -12px !important;
}

/* Set a negative $size margin on the left at $breakpoint */
.ml-n12 {
  margin-left: -12px !important;
}

/* Set a $size margin on the left & right at $breakpoint */
.mx-12 {
  margin-right: 12px !important;
  margin-left: 12px !important;
}

/* Set a $size margin on the top & bottom at $breakpoint */
.my-12 {
  margin-top: 12px !important;
  margin-bottom: 12px !important;
}

/* Set a $size margin to all sides at $breakpoint */
.m-16 {
  margin: 16px !important;
}

.p-16 {
  padding: 16px !important;
}

/* Set a $size margin on the top at $breakpoint */
.mt-16 {
  margin-top: 16px !important;
}

.pt-16 {
  padding-top: 16px !important;
}

/* Set a $size margin on the right at $breakpoint */
.mr-16 {
  margin-right: 16px !important;
}

.pr-16 {
  padding-right: 16px !important;
}

/* Set a $size margin on the bottom at $breakpoint */
.mb-16 {
  margin-bottom: 16px !important;
}

.pb-16 {
  padding-bottom: 16px !important;
}

/* Set a $size margin on the left at $breakpoint */
.ml-16 {
  margin-left: 16px !important;
}

.pl-16 {
  padding-left: 16px !important;
}

/* Set a negative $size margin on top at $breakpoint */
.mt-n16 {
  margin-top: -16px !important;
}

/* Set a negative $size margin on the right at $breakpoint */
.mr-n16 {
  margin-right: -16px !important;
}

/* Set a negative $size margin on the bottom at $breakpoint */
.mb-n16 {
  margin-bottom: -16px !important;
}

/* Set a negative $size margin on the left at $breakpoint */
.ml-n16 {
  margin-left: -16px !important;
}

/* Set a $size margin on the left & right at $breakpoint */
.mx-16 {
  margin-right: 16px !important;
  margin-left: 16px !important;
}

/* Set a $size margin on the top & bottom at $breakpoint */
.my-16 {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}

/* Set a $size margin to all sides at $breakpoint */
.m-24 {
  margin: 24px !important;
}

.p-24 {
  padding: 24px !important;
}

/* Set a $size margin on the top at $breakpoint */
.mt-24 {
  margin-top: 24px !important;
}

.pt-24 {
  padding-top: 24px !important;
}

/* Set a $size margin on the right at $breakpoint */
.mr-24 {
  margin-right: 24px !important;
}

.pr-24 {
  padding-right: 24px !important;
}

/* Set a $size margin on the bottom at $breakpoint */
.mb-24 {
  margin-bottom: 24px !important;
}

.pb-24 {
  padding-bottom: 24px !important;
}

/* Set a $size margin on the left at $breakpoint */
.ml-24 {
  margin-left: 24px !important;
}

.pl-24 {
  padding-left: 24px !important;
}

/* Set a negative $size margin on top at $breakpoint */
.mt-n24 {
  margin-top: -24px !important;
}

/* Set a negative $size margin on the right at $breakpoint */
.mr-n24 {
  margin-right: -24px !important;
}

/* Set a negative $size margin on the bottom at $breakpoint */
.mb-n24 {
  margin-bottom: -24px !important;
}

/* Set a negative $size margin on the left at $breakpoint */
.ml-n24 {
  margin-left: -24px !important;
}

/* Set a $size margin on the left & right at $breakpoint */
.mx-24 {
  margin-right: 24px !important;
  margin-left: 24px !important;
}

/* Set a $size margin on the top & bottom at $breakpoint */
.my-24 {
  margin-top: 24px !important;
  margin-bottom: 24px !important;
}

/* Set a $size margin to all sides at $breakpoint */
.m-32 {
  margin: 32px !important;
}

.p-32 {
  padding: 32px !important;
}

/* Set a $size margin on the top at $breakpoint */
.mt-32 {
  margin-top: 32px !important;
}

.pt-32 {
  padding-top: 32px !important;
}

/* Set a $size margin on the right at $breakpoint */
.mr-32 {
  margin-right: 32px !important;
}

.pr-32 {
  padding-right: 32px !important;
}

/* Set a $size margin on the bottom at $breakpoint */
.mb-32 {
  margin-bottom: 32px !important;
}

.pb-32 {
  padding-bottom: 32px !important;
}

/* Set a $size margin on the left at $breakpoint */
.ml-32 {
  margin-left: 32px !important;
}

.pl-32 {
  padding-left: 32px !important;
}

/* Set a negative $size margin on top at $breakpoint */
.mt-n32 {
  margin-top: -32px !important;
}

/* Set a negative $size margin on the right at $breakpoint */
.mr-n32 {
  margin-right: -32px !important;
}

/* Set a negative $size margin on the bottom at $breakpoint */
.mb-n32 {
  margin-bottom: -32px !important;
}

/* Set a negative $size margin on the left at $breakpoint */
.ml-n32 {
  margin-left: -32px !important;
}

/* Set a $size margin on the left & right at $breakpoint */
.mx-32 {
  margin-right: 32px !important;
  margin-left: 32px !important;
}

/* Set a $size margin on the top & bottom at $breakpoint */
.my-32 {
  margin-top: 32px !important;
  margin-bottom: 32px !important;
}

/* Set a $size margin to all sides at $breakpoint */
.m-40 {
  margin: 40px !important;
}

.p-40 {
  padding: 40px !important;
}

/* Set a $size margin on the top at $breakpoint */
.mt-40 {
  margin-top: 40px !important;
}

.pt-40 {
  padding-top: 40px !important;
}

/* Set a $size margin on the right at $breakpoint */
.mr-40 {
  margin-right: 40px !important;
}

.pr-40 {
  padding-right: 40px !important;
}

/* Set a $size margin on the bottom at $breakpoint */
.mb-40 {
  margin-bottom: 40px !important;
}

.pb-40 {
  padding-bottom: 40px !important;
}

/* Set a $size margin on the left at $breakpoint */
.ml-40 {
  margin-left: 40px !important;
}

.pl-40 {
  padding-left: 40px !important;
}

/* Set a negative $size margin on top at $breakpoint */
.mt-n40 {
  margin-top: -40px !important;
}

/* Set a negative $size margin on the right at $breakpoint */
.mr-n40 {
  margin-right: -40px !important;
}

/* Set a negative $size margin on the bottom at $breakpoint */
.mb-n40 {
  margin-bottom: -40px !important;
}

/* Set a negative $size margin on the left at $breakpoint */
.ml-n40 {
  margin-left: -40px !important;
}

/* Set a $size margin on the left & right at $breakpoint */
.mx-40 {
  margin-right: 40px !important;
  margin-left: 40px !important;
}

/* Set a $size margin on the top & bottom at $breakpoint */
.my-40 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

/* Set a $size margin to all sides at $breakpoint */
.m-48 {
  margin: 48px !important;
}

.p-48 {
  padding: 48px !important;
}

/* Set a $size margin on the top at $breakpoint */
.mt-48 {
  margin-top: 48px !important;
}

.pt-48 {
  padding-top: 48px !important;
}

/* Set a $size margin on the right at $breakpoint */
.mr-48 {
  margin-right: 48px !important;
}

.pr-48 {
  padding-right: 48px !important;
}

/* Set a $size margin on the bottom at $breakpoint */
.mb-48 {
  margin-bottom: 48px !important;
}

.pb-48 {
  padding-bottom: 48px !important;
}

/* Set a $size margin on the left at $breakpoint */
.ml-48 {
  margin-left: 48px !important;
}

.pl-48 {
  padding-left: 48px !important;
}

/* Set a negative $size margin on top at $breakpoint */
.mt-n48 {
  margin-top: -48px !important;
}

/* Set a negative $size margin on the right at $breakpoint */
.mr-n48 {
  margin-right: -48px !important;
}

/* Set a negative $size margin on the bottom at $breakpoint */
.mb-n48 {
  margin-bottom: -48px !important;
}

/* Set a negative $size margin on the left at $breakpoint */
.ml-n48 {
  margin-left: -48px !important;
}

/* Set a $size margin on the left & right at $breakpoint */
.mx-48 {
  margin-right: 48px !important;
  margin-left: 48px !important;
}

/* Set a $size margin on the top & bottom at $breakpoint */
.my-48 {
  margin-top: 48px !important;
  margin-bottom: 48px !important;
}

/* Set a $size margin to all sides at $breakpoint */
.m-64 {
  margin: 64px !important;
}

.p-64 {
  padding: 64px !important;
}

/* Set a $size margin on the top at $breakpoint */
.mt-64 {
  margin-top: 64px !important;
}

.pt-64 {
  padding-top: 64px !important;
}

/* Set a $size margin on the right at $breakpoint */
.mr-64 {
  margin-right: 64px !important;
}

.pr-64 {
  padding-right: 64px !important;
}

/* Set a $size margin on the bottom at $breakpoint */
.mb-64 {
  margin-bottom: 64px !important;
}

.pb-64 {
  padding-bottom: 64px !important;
}

/* Set a $size margin on the left at $breakpoint */
.ml-64 {
  margin-left: 64px !important;
}

.pl-64 {
  padding-left: 64px !important;
}

/* Set a negative $size margin on top at $breakpoint */
.mt-n64 {
  margin-top: -64px !important;
}

/* Set a negative $size margin on the right at $breakpoint */
.mr-n64 {
  margin-right: -64px !important;
}

/* Set a negative $size margin on the bottom at $breakpoint */
.mb-n64 {
  margin-bottom: -64px !important;
}

/* Set a negative $size margin on the left at $breakpoint */
.ml-n64 {
  margin-left: -64px !important;
}

/* Set a $size margin on the left & right at $breakpoint */
.mx-64 {
  margin-right: 64px !important;
  margin-left: 64px !important;
}

/* Set a $size margin on the top & bottom at $breakpoint */
.my-64 {
  margin-top: 64px !important;
  margin-bottom: 64px !important;
}

/* responsive horizontal auto margins */
.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.pagination__list {
  display: flex;
  align-items: center;
}
.pagination__item {
  margin-right: 32px;
}
@media (max-width: 767px) {
  .pagination__item {
    margin-right: 8px;
  }
}
.pagination__item:last-child {
  margin-right: 0;
}
.pagination__link {
  display: inline-block;
  padding: 8px;
  color: #1c5335;
  text-decoration: none;
}
.pagination__link--active {
  text-decoration: underline;
}

.pagination--spread .pagination__item:first-child, .pagination--spread .pagination__item:nth-last-child(2) {
  margin-right: 72px;
}
@media (max-width: 767px) {
  .pagination--spread .pagination__item:first-child, .pagination--spread .pagination__item:nth-last-child(2) {
    margin-right: 16px;
  }
}

.pagination--center {
  margin: 40px auto 20px;
}
.pagination--center .pagination__list {
  justify-content: center;
}

.add-more-list__button {
  background: none;
  border: 0;
  color: #1c5335;
  font-size: 18px;
  cursor: pointer;
}
.add-more-list__button .icon {
  pointer-events: none;
  width: 22px;
  height: 22px;
}

/**
  * The split() method turns a String into an array of strings,
  * by separating the string at each instance of a specified separator string.
 */
/**
  * Add transition to mulitple properties at once.
  * @example
  * @include transition('transform, color', .3s);
 */
/**
 This is the order at which the elements will stack.
 List goes from lowest to highest.
 LOWEST -------------------> HIGHEST
 *Example:
 @include z-index('navigation');
 */
/**
 * Check if RTL is enabled.
 */
/**
 * Invert side of the margin.
 */
/**
 * If RTL Mode.
 */
/**
 * Reverse row for flex items
 */
/**
 * Add spacing to the right side
 */
/**
 * Add padding to the right side
 */
/**
	* Given a property flips its side for RLT.
	* @example
	*
	* @include flip(border-left, 1px solid red)
	* // => border-right: 1px solid red;
	*
	* @include flip(border-top-left-radius, 1px)
	* // => border-top-right-radius: 1px;
	*
	* @include flip(border-top, 1px solid red)
	* // => border-top: 1px solid red;
 */
.dropdown {
  cursor: pointer;
  line-height: 1;
}

.dropdown-menu {
  display: none;
  position: absolute;
  border: 1px solid #1c5335;
  box-shadow: 3px 3px 16px rgba(0, 0, 0, 0.16);
  margin: 0;
  padding: 0;
  min-width: 160px;
  background: #ffffff;
  color: #1c5335;
  font-size: 16px;
  list-style: none;
  z-index: 1080;
}

.dropdown-menu.active {
  display: block;
}

.dropdown__indicator {
  transition: transform 0.2s ease-in-out;
}

.dropdown__indicator.active {
  transform: rotate(180deg);
}

.dropdown__item {
  display: flex;
  align-items: center;
  width: 100%;
  height: 48px;
  padding: 0 16px;
  transition-property: background-color, color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  color: #1c5335;
  font-size: 16px;
  white-space: nowrap;
  text-decoration: none;
  text-align: left;
}
.dropdown__item:hover {
  background-color: #1c5335;
  color: #fff;
  cursor: pointer;
}

@media (max-width: 767.98px) {
  .dropdown__indicator {
    display: none;
  }
  .dropdown--expend-tablet .dropdown__trigger {
    display: none;
  }
  .dropdown--expend-tablet .dropdown-menu {
    display: block;
    position: static;
    background: none;
    border: 0;
    box-shadow: none;
  }
  .dropdown--expend-tablet .dropdown__item {
    text-decoration: underline;
    padding: 0;
  }
  .dropdown--expend-tablet .dropdown__item:hover {
    background-color: transparent;
    color: #e86825;
  }
}
@media (max-width: 1023.98px) {
  .dropdown--expend-laptop .dropdown__trigger {
    display: none;
  }
  .dropdown--expend-laptop .dropdown-menu {
    display: block;
    position: static;
    background: none;
    border: 0;
    box-shadow: none;
  }
  .dropdown--expend-laptop .dropdown__item {
    text-decoration: underline;
    padding: 0;
  }
  .dropdown--expend-laptop .dropdown__item:hover {
    background-color: transparent;
    color: #e86825;
  }
}
.text-editor {
  border: 1px solid #1c5335;
  padding: 12px;
  min-height: 114px;
}
.text-editor.text-editor--lg {
  min-height: 200px;
}
.text-editor.text-editor--lg .ProseMirror {
  height: 200px;
}
.text-editor .rte-menu-bar {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 8px;
}
.text-editor .rte-menu-bar .rte-menu-bar--btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  margin-inline-end: 8px;
  padding: 2px 0;
  width: 20px;
  height: 20px;
  background: #fff;
  color: #1c5335;
  font-size: 16px;
  -webkit-appearance: none;
  cursor: pointer;
}
.text-editor .rte-menu-bar .rte-menu-bar--btn:last-child {
  margin-inline-end: 0;
}
.text-editor .rte-menu-bar .rte-menu-bar--btn.active {
  background: #ffe8e8;
  color: #1c5335;
}
.text-editor .rte-menu-bar--extended {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-inline-start: 1px solid #bacbc2;
  padding-inline-start: 8px;
}
.text-editor .ProseMirror {
  height: 114px;
  line-height: inherit;
  overflow-y: auto;
  outline: none;
}
.text-editor .ProseMirror p,
.text-editor .ProseMirror h4,
.text-editor .ProseMirror ul {
  margin-top: 16px;
  margin-bottom: 0;
}
.text-editor .ProseMirror a {
  text-decoration: underline;
  cursor: pointer;
  transition: color 0.2s ease-in-out;
}
.text-editor .ProseMirror::-webkit-scrollbar {
  width: 12px;
}
.text-editor .ProseMirror::-webkit-scrollbar-track {
  background: #f8f8f8;
}
.text-editor .ProseMirror::-webkit-scrollbar-thumb {
  background-color: #bacbc2;
  border-radius: 20px;
}

.modal-two-columns {
  display: grid;
  grid-template-columns: repeat(1, minmax(max-content, 1fr));
  column-gap: 40px;
  row-gap: 24px;
}

.modal__info-item__title {
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  color: #e86825;
}

.modal__info-item__description {
  margin-top: 8px;
}

@media (min-width: 768px) {
  .modal-two-columns {
    grid-template-columns: repeat(2, minmax(max-content, 1fr));
  }
}
.email-preview {
  margin: 0;
  padding: 20px;
  border: 1px solid #d4ddd8;
  max-width: 1200px;
  width: 100%;
  height: 600px;
  resize: both;
}

.single-info-item .line-through,
.modal__info-item .line-through {
  text-decoration: line-through;
}
.single-info-item span.line-through,
.modal__info-item span.line-through {
  color: #f6c3a8;
}
.single-info-item p.line-through,
.modal__info-item p.line-through {
  color: #6b8f7c;
}

.three-column-section {
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;
}

@media (min-width: 768px) {
  .three-column-section {
    grid-template-columns: repeat(2, minmax(max-content, 1fr));
  }
}
@media (min-width: 1280px) {
  .three-column-section {
    grid-template-columns: repeat(3, minmax(max-content, 1fr));
  }
}
/**
* DAS => Days And Shifts
 */
.v-treatment-das__calendar.litepicker--inline .litepicker .container__months {
  border: 1px solid #1c5335;
  padding: 24px;
}

.v-treatment-das__shifts > * + * {
  margin-top: 16px;
}
.v-treatment-das__shifts > *:first-child {
  margin-top: 32px;
}
.v-treatment-das__shifts .form-label {
  text-transform: capitalize;
}

.v-treatment-das__shifts-select {
  width: calc(
	var(--litepicker-month-width) + 2 * 24px + 2 * 1px
);
}

.v-treatment-das__close-button {
  padding: 16px 18px;
}
.v-treatment-das__close-button svg {
  color: inherit;
  font-size: inherit;
}

@media (min-width: 1024px) {
  .v-treatment-das__shifts-select {
    min-width: calc(
	var(--litepicker-month-width) + 2 * 24px + 2 * 1px
);
  }
}
.litepicker,
.litepicker[data-plugins*=multiselect] {
  margin: 8px 0;
  font-size: 16px;
  font-family: inherit;
}
.litepicker .container__months,
.litepicker[data-plugins*=multiselect] .container__months {
  border: 1px solid #1c5335;
  box-shadow: 3px 3px 16px rgba(0, 0, 0, 0.16);
  border-radius: 0;
  width: calc(var(--litepicker-month-width) + 24px * 2);
}
.litepicker .container__months .month-item-name,
.litepicker[data-plugins*=multiselect] .container__months .month-item-name {
  text-transform: capitalize;
}
.litepicker .container__months.columns-2,
.litepicker[data-plugins*=multiselect] .container__months.columns-2 {
  width: calc(var(--litepicker-month-width) * 2 + 24px * 4);
}
.litepicker .container__months .month-item-header,
.litepicker[data-plugins*=multiselect] .container__months .month-item-header {
  padding-top: 0;
}
.litepicker .container__months .month-item-weekdays-row > div,
.litepicker[data-plugins*=multiselect] .container__months .month-item-weekdays-row > div {
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--litepicker-day-width);
  font-size: 16px;
  font-weight: 400;
  text-transform: capitalize;
}
.litepicker .container__months .month-item,
.litepicker[data-plugins*=multiselect] .container__months .month-item {
  padding: 24px;
}
.litepicker .container__days .day-item.is-locked,
.litepicker[data-plugins*=multiselect] .container__days .day-item.is-locked {
  text-decoration: line-through;
}
.litepicker .container__days .day-item,
.litepicker[data-plugins*=multiselect] .container__days .day-item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--litepicker-day-width);
  height: var(--litepicker-day-width);
  margin: 4px 0;
  padding: 0;
  border-radius: 0;
}
.litepicker .container__days .day-item:hover,
.litepicker[data-plugins*=multiselect] .container__days .day-item:hover {
  cursor: pointer;
}
.litepicker .container__days .day-item.is-locked:hover,
.litepicker[data-plugins*=multiselect] .container__days .day-item.is-locked:hover {
  cursor: not-allowed;
}
.litepicker .container__days .day-item:not(.is-locked):hover,
.litepicker[data-plugins*=multiselect] .container__days .day-item:not(.is-locked):hover {
  font-weight: normal;
  color: var(--litepicker-day-today-color-hover);
}
.litepicker .container__days .day-item:not(.is-locked):hover::after,
.litepicker[data-plugins*=multiselect] .container__days .day-item:not(.is-locked):hover::after {
  border-radius: 0;
  box-shadow: inset 0 0 0 1px var(--litepicker-day-color-hover);
  width: var(--litepicker-multiselect-day-size-selected);
  height: var(--litepicker-multiselect-day-size-selected);
}
.litepicker .container__days .day-item.is-selected, .litepicker .container__days .day-item.is-start-date, .litepicker .container__days .day-item.is-end-date, .litepicker .container__days .day-item.is-start-date.is-end-date,
.litepicker[data-plugins*=multiselect] .container__days .day-item.is-selected,
.litepicker[data-plugins*=multiselect] .container__days .day-item.is-start-date,
.litepicker[data-plugins*=multiselect] .container__days .day-item.is-end-date,
.litepicker[data-plugins*=multiselect] .container__days .day-item.is-start-date.is-end-date {
  border-radius: 0;
}
.litepicker .container__days .day-item.is-selected.is-flipped, .litepicker .container__days .day-item.is-start-date.is-flipped, .litepicker .container__days .day-item.is-end-date.is-flipped, .litepicker .container__days .day-item.is-start-date.is-end-date.is-flipped,
.litepicker[data-plugins*=multiselect] .container__days .day-item.is-selected.is-flipped,
.litepicker[data-plugins*=multiselect] .container__days .day-item.is-start-date.is-flipped,
.litepicker[data-plugins*=multiselect] .container__days .day-item.is-end-date.is-flipped,
.litepicker[data-plugins*=multiselect] .container__days .day-item.is-start-date.is-end-date.is-flipped {
  border-radius: 0;
}
.litepicker .container__days .day-item.is-selected:hover, .litepicker .container__days .day-item.is-start-date:hover, .litepicker .container__days .day-item.is-end-date:hover, .litepicker .container__days .day-item.is-start-date.is-end-date:hover,
.litepicker[data-plugins*=multiselect] .container__days .day-item.is-selected:hover,
.litepicker[data-plugins*=multiselect] .container__days .day-item.is-start-date:hover,
.litepicker[data-plugins*=multiselect] .container__days .day-item.is-end-date:hover,
.litepicker[data-plugins*=multiselect] .container__days .day-item.is-start-date.is-end-date:hover {
  font-weight: normal;
  color: #fff;
}
.litepicker .container__days .day-item.is-selected::after, .litepicker .container__days .day-item.is-start-date::after, .litepicker .container__days .day-item.is-end-date::after, .litepicker .container__days .day-item.is-start-date.is-end-date::after,
.litepicker[data-plugins*=multiselect] .container__days .day-item.is-selected::after,
.litepicker[data-plugins*=multiselect] .container__days .day-item.is-start-date::after,
.litepicker[data-plugins*=multiselect] .container__days .day-item.is-end-date::after,
.litepicker[data-plugins*=multiselect] .container__days .day-item.is-start-date.is-end-date::after {
  border-radius: 0;
  width: var(--litepicker-multiselect-day-size-selected);
  height: var(--litepicker-multiselect-day-size-selected);
}

.litepicker[data-plugins*=multiselect] .day-item.is-selected:hover {
  background-color: var(--litepicker-highlighted-day-color-bg);
  color: var(--litepicker-highlighted-day-color);
}

.litepicker--inline .litepicker {
  margin: initial;
  z-index: 0 !important;
}
.litepicker--inline .litepicker .container__months {
  box-shadow: none;
  border: none;
  width: var(--litepicker-month-width);
}
.litepicker--inline .litepicker .container__months.columns-2 {
  width: calc(var(--litepicker-month-width) * 2 + 24px * 2);
}
.litepicker--inline .litepicker .container__months.columns-2 .month-item + .month-item {
  padding-left: 48px;
}
.litepicker--inline .litepicker .container__months .month-item {
  padding: 0;
}

.litepicker .container__months .month-item-header .button-previous-month,
.litepicker .container__months .month-item-header .button-next-month {
  cursor: pointer;
}

:root {
  --litepicker-container-months-color-bg: #fff;
  --litepicker-container-months-box-shadow-color: #ddd;
  --litepicker-footer-color-bg: #fafafa;
  --litepicker-footer-box-shadow-color: #ddd;
  --litepicker-tooltip-color-bg: #fff;
  --litepicker-month-header-color: #1c5335;
  --litepicker-button-prev-month-color: #e86825;
  --litepicker-button-next-month-color: #e86825;
  --litepicker-button-prev-month-color-hover: #1c5335;
  --litepicker-button-next-month-color-hover: #1c5335;
  --litepicker-month-width: calc(var(--litepicker-day-width) * 7);
  --litepicker-month-weekday-color: #1c5335;
  --litepicker-month-week-number-color: #1c5335;
  --litepicker-day-width: 36px;
  --litepicker-multiselect-day-size-selected: 36px;
  --litepicker-day-color: #1c5335;
  --litepicker-day-color-hover: #e86825;
  --litepicker-day-today-color-hover: #e86825;
  --litepicker-multiselect-is-selected-color: #fff;
  --litepicker-multiselect-is-selected-color-bg: #e86825;
  --litepicker-is-today-color: #e86825;
  --litepicker-is-in-range-color: #ffebe1;
  --litepicker-is-locked-color: #bacbc2;
  --litepicker-is-start-color: #fff;
  --litepicker-is-start-color-bg: #e86825;
  --litepicker-is-end-color: #fff;
  --litepicker-is-end-color-bg: #e86825;
  --litepicker-button-cancel-color: #fff;
  --litepicker-button-cancel-color-bg: #9e9e9e;
  --litepicker-button-apply-color: #fff;
  --litepicker-button-apply-color-bg: #e86825;
  --litepicker-button-reset-color: #909090;
  --litepicker-button-reset-color-hover: #e86825;
  --litepicker-highlighted-day-color: #1c5335;
  --litepicker-highlighted-day-color-bg: #ffebe1;
}

.litepicker {
  z-index: 1060 !important;
}

@media (max-width: 1023px) {
  .autocomplete-module-modal-wrapper .modal__container {
    width: 100%;
    height: 100%;
    max-width: 100%;
  }
  .autocomplete-module-modal-wrapper .modal__content,
.autocomplete-module-modal-wrapper .modal__footer {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (max-width: 767px) {
  .autocomplete-module-modal-wrapper .modal__content,
.autocomplete-module-modal-wrapper .modal__footer {
    max-width: 300px;
  }
}
:root {
  --litepicker-container-months-color-bg: #fff;
  --litepicker-container-months-box-shadow-color: #ddd;
  --litepicker-footer-color-bg: #fafafa;
  --litepicker-footer-box-shadow-color: #ddd;
  --litepicker-tooltip-color-bg: #fff;
  --litepicker-month-header-color: #1c5335;
  --litepicker-button-prev-month-color: #e86825;
  --litepicker-button-next-month-color: #e86825;
  --litepicker-button-prev-month-color-hover: #1c5335;
  --litepicker-button-next-month-color-hover: #1c5335;
  --litepicker-month-width: calc(var(--litepicker-day-width) * 7);
  --litepicker-month-weekday-color: #1c5335;
  --litepicker-month-week-number-color: #1c5335;
  --litepicker-day-width: 36px;
  --litepicker-multiselect-day-size-selected: 36px;
  --litepicker-day-color: #1c5335;
  --litepicker-day-color-hover: #e86825;
  --litepicker-multiselect-is-selected-color: #fff;
  --litepicker-multiselect-is-selected-color-bg: #e86825;
  --litepicker-is-today-color: #e86825;
  --litepicker-is-in-range-color: #ffebe1;
  --litepicker-is-locked-color: #bacbc2;
  --litepicker-is-start-color: #fff;
  --litepicker-is-start-color-bg: #e86825;
  --litepicker-is-end-color: #fff;
  --litepicker-is-end-color-bg: #e86825;
  --litepicker-button-cancel-color: #fff;
  --litepicker-button-cancel-color-bg: #9e9e9e;
  --litepicker-button-apply-color: #fff;
  --litepicker-button-apply-color-bg: #e86825;
  --litepicker-button-reset-color: #909090;
  --litepicker-button-reset-color-hover: #e86825;
  --litepicker-highlighted-day-color: #1c5335;
  --litepicker-highlighted-day-color-bg: #ffebe1;
}

@media (max-width: 1023px) {
  .navigation__search-form--lite-picker .litepicker .container__days > div,
.navigation__search-form--lite-picker .litepicker .container__days .day-item,
.clinic-booking-form--lite-picker .litepicker .container__days > div,
.clinic-booking-form--lite-picker .litepicker .container__days .day-item,
.home-hero-search-modal .litepicker .container__days > div,
.home-hero-search-modal .litepicker .container__days .day-item {
    width: 14.2857142857%;
  }
  .navigation__search-form--lite-picker .litepicker .container__main,
.clinic-booking-form--lite-picker .litepicker .container__main,
.home-hero-search-modal .litepicker .container__main {
    width: 100%;
  }
  .navigation__search-form--lite-picker .litepicker .container__months,
.clinic-booking-form--lite-picker .litepicker .container__months,
.home-hero-search-modal .litepicker .container__months {
    width: 100%;
    border: 0;
  }
  .navigation__search-form--lite-picker .litepicker .month-item,
.clinic-booking-form--lite-picker .litepicker .month-item,
.home-hero-search-modal .litepicker .month-item {
    width: 100%;
  }
}