$color-orange-medium: $line-through-color;
$color-green-medium: $line-through-grayout;

.single-info-item,
.modal__info-item {
	.line-through {
		text-decoration: line-through;
	}
	span.line-through {
		color: $color-orange-medium;
	}
	p.line-through {
		color: $color-green-medium;
	}
}

.three-column-section {
	display: grid;
	grid-template-columns: 1fr;
	gap: 24px;
}

@include tablet {
	.three-column-section {
		grid-template-columns: repeat(2, minmax(max-content, 1fr));
	}
}

@include desktop {
	.three-column-section {
		grid-template-columns: repeat(3, minmax(max-content, 1fr));
	}
}
