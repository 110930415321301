.min-w-100 {
	min-width: 100px;
}

.min-w-125 {
	min-width: 125px;
}

.min-w-150 {
	min-width: 150px;
}

.min-w-175 {
	min-width: 175px;
}

.min-w-200 {
	min-width: 200px;
}

.min-w-225 {
	min-width: 225px;
}

.min-w-250 {
	min-width: 250px;
}
