/***************************************
    Layout
****************************************/

//sidebarmenu
$sidemenu-background-color: $color-brand-primary;
$sidemenu-responsive-items: $color-brand-primary;
$sidemenu-active-color: #ffebe1;

// primary button
$primary-button-bg: $color-brand-secondary;
$primary-button-bg-hover: $color-brand-primary;
$primary-button-bg-active: darken(desaturate(adjust-hue($color-brand-primary, 15), 30.01), 4.71);
$primary-button-disabled: 0.4;

// primary inverted
$primary-inverted-button-bg: #ffffff;
$primary-inverted-border-color: #3a564f;
$primary-inverted-button-color: #3a564f;
$primary-inverted-button-hover-bg: #e9ebea;
$primary-inverted-button-active-bg: #e9ebea;

// gray button
$gray-button-bg: #f8f8f8;
$gray-button-color: #000000;
$gray-button-bg-hover: #ffebe1;
$gray-button-bg-active: #ebebeb;

// button underline
$button-underline-color: $color-brand-primary;
$button-underline-hover-color: $color-brand-secondary;
$button-underline-active-color: #808080;
$button-underline-disable-color: $color-green-medium;
$button-underline-svg-color: #5d7d6a;

//input
$input-border-color: $color-brand-primary;
$input-color: $color-brand-primary;
$input-label-color: $color-brand-primary;
$input-border-hover-color: $color-brand-primary;
$input-border-focus-color: $color-brand-primary;
$input-background-color-disabled: transparent;
$input-color-disabled: #becac2;
$input-disable-border-color: #becac2;
$input-close-color: $color-brand-primary;
$input-with-icon-b-color: $color-brand-primary;
$input-error-text-color: #e40000;
$input-placeholder-color: #6b8f7c;
$help-text-label-color: #6b8f7c;
$input-error-color: $color-red-main;

//Auth screen
$auth-text-color: #5d7d6a;

// table
$table-header-background: #f8f8f8;
$table-border-color: #d4ddd8;
$table-sorter-color: #9396af;
$table-arrow-color: $color-brand-primary;
$pagination-item-color: $color-brand-primary;
$table-text-color: $color-brand-primary;

//checkbox
$checkbox-label: $color-green-primary;
$checkbox-background-color: #fff;
$checkbox-border: $color-green-primary;

$checkbox-background-color-checked: $color-white;
$checkbox-border-checked: $color-green-primary;
$checkbox-checkmark: $color-orange-primary;

//Dropdown
$dropdown-border-color: $color-brand-primary;

//Text editor
$text-editor-color: $color-brand-primary;
$text-editor-btn-color: $color-brand-primary;
$text-editor-btn-bg-color: #ffe8e8;

// Tabs
$tab-active-border-color: #e86825;

// Download file
$download-file-bg-color: $gray-button-bg;
$download-file-svg-color: $color-brand-primary;
$download-file-hover-bg-color: $gray-button-bg-hover;

// Remove file
$remove-file-bg-color: $download-file-bg-color;
$remove-file-svg-color: $download-file-svg-color;
$remove-file-svg-hover-color: $download-file-svg-color;

// Upload file
$upload-file-color: $color-brand-primary;
$upload-file-border-color: #becac2;
$upload-file-button-border-color: $color-brand-primary;

// flash messages
$flash-message-error-background: #f76363;
$flash-message-success-background: #57a88a;
$flash-message-warning-background: #f8a116;

// Single item heading
$info-item-heading-color: $color-brand-secondary;
$info-item-p-color: $color-brand-primary;

//labels
$line-through-color: $color-orange-medium;
$line-through-grayout: $color-green-medium;
$label-completed-background-color: #57a88a;

//login
$login-heading-color: $color-brand-primary;

//Auth screen
$auth-background-color: #e5e5e5;

//Overview
$overview-box-heading-color: #282828;
$overview-box-text-color: $color-brand-primary;

/***************************************
    Platform
****************************************/
// clinic booking
//----------------------------------------
$booking-card-document-border-color: $color-green-light;

//popover colors
$popover-close-button-color: $color-brand-secondary;
$popover-footer-border-color: $color-green-light;

//input stepper
$stepper-height: 48px;
$stepper-border-color: $color-brand-primary;
$stepper-button-background: $color-brand-primary;
$stepper-button-disabled-background: $color-gray-light;
$stepper-button-disabled-color: $color-green-light;
$stepper-button-error-border-color: $color-red-main;
$stepper-input-color: $color-green-primary;
$stepper-button-color: #ffffff;
$stepper-input-disabled-color: $color-green-medium;

//patient
$item-active-color: #fff;
$item-background: $color-gray-brown-light;
$item-active-background: $color-brand-primary;
$item-hover-background: $color-green-light;
$item-arrow-color: $color-brand-secondary;
$item-input-border-color: $input-border-color;

//clinic booking
$clinic-booking-request-color: $color-green-medium;
$booking-clinic-price-label-color: $color-green-primary;

//schedule labels
$schedule-label-change-color: $color-orange-dark;
$schedule-label-remove-color: $color-red-main;
$schedule-label-add-color: $color-green-shift;
$schedule-label-line-through-color: $color-green-medium;
$booking-status-message-background-color: $color-green-lighter;
$booking-status-message-border-color: transparent;
$booking-status-content-color: $color-green-medium;
$clinic-card-small-border-top-color: $color-green-light;
$booking-status-message-border-color-red: transparent;
$booking-status-message-background-color-red: #ffebe1;
$booking-status-background-color: $color-gray-light;

//tooltip
$tooltip-border-color: $color-brand-primary;

//new patient
$patient-upcoming-booking-icon-color: $color-orange-primary;

//dropdown
$dropdown-hover-color: $color-white;

//calendar
$calendar-active-day-background-color: $color-brand-secondary;
$calendar-indicator-yellow-color: $color-yellow-main;
$calendar-indicator-orange-color: $color-orange-dark;
$calendar-indicator-green-color: #63b98a;

//book treatment order numbers
$booking-card-number-border-color: $color-green-primary;
$booking-card-number-background-color: transparent;
$booking-card-number-color: inherit;
$booking-card-number-background-active-color: $color-green-primary;

//price label
$booking-clinic-price-label-background-color: $color-green-primary;
$booking-clinic-price-color: white;
$booking-card-number-disabled-color: $color-green-medium;
$booking-card-number-disabled-background-color: $color-green-medium;

//buttons
$button-circled-disabled-color: $color-green-light;
$button-circled-disabled-border-color: $color-green-light;

//Update clinic
$update-clinic-profile-card-border-color: $color-green-light;

$time-not-set-color: #bacbc2;
