.two-column-section {
	&__title {
		margin: 0 0 24px 0;
		padding-bottom: 8px;
		border-bottom: 1px solid $tabs-component-border;
		@extend %flex-center;
		@include font-size(24px, 32px);
		font-weight: $font-weight-medium;
	}

	&__subtitle {
		margin: 0 0 12px 0;
		@extend %flex-center;
		@include font-size(18px, 32px);
		font-weight: $font-weight-medium;
	}

	&__title + .two-column-section__subtitle,
	.two-column-wrapper + .two-column-section__subtitle {
		margin-top: 32px;
	}

	.two-column-wrapper {
		margin-bottom: 24px;
		display: flex;
		justify-content: flex-start;

		@include breakpoint(phablet) {
			flex-direction: column;
		}

		.form-group {
			margin: 0;
			flex-basis: 50%;
			justify-self: flex-end;
			input,
			select,
			textarea,
			.text-editor {
				max-width: 100%;
			}
		}

		.single-info-item {
			padding-right: 48px;
			flex-basis: 50%;
		}
	}
}
