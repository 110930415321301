// These are our margin and padding utility spacers.
$spacing-00: 0 !default;
$spacing-02: 2px !default;
$spacing-04: 4px !default;
$spacing-08: 8px !default;
$spacing-12: 12px !default;
$spacing-16: 16px !default;
$spacing-24: 24px !default;
$spacing-32: 32px !default;
$spacing-40: 40px !default;
$spacing-48: 48px !default;
$spacing-64: 64px !default;

$spacer-map: (
	0: $spacing-00,
	2: $spacing-02,
	4: $spacing-04,
	8: $spacing-08,
	12: $spacing-12,
	16: $spacing-16,
	24: $spacing-24,
	32: $spacing-32,
	40: $spacing-40,
	48: $spacing-48,
	64: $spacing-64,
) !default;

@each $scale, $size in $spacer-map {
	/* Set a $size margin to all sides at $breakpoint */
	.m-#{$scale} {
		margin: $size !important;
	}
	.p-#{$scale} {
		padding: $size !important;
	}

	/* Set a $size margin on the top at $breakpoint */
	.mt-#{$scale} {
		margin-top: $size !important;
	}
	.pt-#{$scale} {
		padding-top: $size !important;
	}

	/* Set a $size margin on the right at $breakpoint */
	.mr-#{$scale} {
		margin-right: $size !important;
	}
	.pr-#{$scale} {
		padding-right: $size !important;
	}

	/* Set a $size margin on the bottom at $breakpoint */
	.mb-#{$scale} {
		margin-bottom: $size !important;
	}
	.pb-#{$scale} {
		padding-bottom: $size !important;
	}

	/* Set a $size margin on the left at $breakpoint */
	.ml-#{$scale} {
		margin-left: $size !important;
	}
	.pl-#{$scale} {
		padding-left: $size !important;
	}

	@if ($size != 0) {
		/* Set a negative $size margin on top at $breakpoint */
		.mt-n#{$scale} {
			margin-top: -$size !important;
		}

		/* Set a negative $size margin on the right at $breakpoint */
		.mr-n#{$scale} {
			margin-right: -$size !important;
		}

		/* Set a negative $size margin on the bottom at $breakpoint */
		.mb-n#{$scale} {
			margin-bottom: -$size !important;
		}

		/* Set a negative $size margin on the left at $breakpoint */
		.ml-n#{$scale} {
			margin-left: -$size !important;
		}
	}

	/* Set a $size margin on the left & right at $breakpoint */
	.mx-#{$scale} {
		margin-right: $size !important;
		margin-left: $size !important;
	}

	/* Set a $size margin on the top & bottom at $breakpoint */
	.my-#{$scale} {
		margin-top: $size !important;
		margin-bottom: $size !important;
	}
}

/* responsive horizontal auto margins */
.mx-auto {
	margin-right: auto !important;
	margin-left: auto !important;
}
