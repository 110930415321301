$warning-red: $error-color;
$alert-yellow: #f7d663;
$good-green: #3a564f;

.circle-chart {
	overflow: visible;

	&__circle {
		animation: circle-chart-fill 2s reverse; /* 1 */
		transform: rotate(-90deg); /* 2, 3 */
		transform-origin: center; /* 4 */

		&.circle-orange {
			stroke: $warning-red;
		}

		&.circle-yellow {
			stroke: $alert-yellow;
		}

		&.circle-green {
			stroke: $good-green;
		}
	}

	&__background {
		stroke: rgb(245, 246, 240);
	}

	&__info {
		font-weight: bold;
		fill: #000000;
		color: #000;
	}

	&__number {
		@include font-size(12px, 16px);
	}

	&__unit {
		font-size: 6px;
		font-weight: 400;
	}

	&__wrapper {
		display: inline-flex;
		align-items: center;
		justify-content: center;
	}
}
