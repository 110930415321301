$global-text-direction: ltr !default;

/***************************************
    Colors
****************************************/

$color-brand-primary: #1c5335;
$color-brand-secondary: #e86825;

$color-green-primary: #1c5335;
$color-green-medium: #6b8f7c;
$color-green: #cee1c6;
$color-green-light: #bacbc2;
$color-green-lighter: #e7f2e3;
$color-green-shift: #63b98a;

$color-orange-dark: #c84603;
$color-orange-primary: #e86825;
$color-orange-medium: #f6c3a8;
$color-orange-light: #ffebe1;

$color-yellow-main: #ffb31b;
$color-yellow-light: #fff3db;

$color-red-main: #c80303;
$color-red-light: #fff3db;

$color-gray-main: #c9c9c9;
$color-gray-light: #f8f8f8;
$color-gray-brown-light: #f8f3f0;

$color-white: #fff;

// global
$global-border-color: $color-green-light;

/***************************************
    Typography
****************************************/

$font-path: '../../../fonts/euclidCircularA/EuclidCircularA';
$font-family: 'Euclid';

$primary-font: 'Euclid', sans-serif, 'Segoe UI Emoji', 'Segoe UI Symbol';

$global-font-family: 'EuclidCircular', sans-serif;
$global-font-color: $color-brand-primary;
$global-font-size: 16px;
$global-line-height: 24px;
$global-small-font-size: 12px;

// with @ fontFace mixin create font file
$font-weight-name: 'Medium';
$font-weight-medium: 500;

$global-link-font-color: $global-font-color;
$global-link-hover-font-color: $color-brand-secondary;

$heading-4-color: $color-brand-secondary;
$header-hero-font-size: 48px;
$header-h1-font-size: 34px;
$header-h2-font-size: 24px;
$header-h3-font-size: 20px;
$header-h4-font-size: 12px;

$header-hero-font-size-desktop: 68px;
$header-h1-font-size-desktop: 48px;
$header-h2-font-size-desktop: 32px;
$header-h3-font-size-desktop: 24px;
$header-h4-font-size-desktop: 16px;

$font-weight-heading: 400;

/***************************************
    Containers
****************************************/
$container-large-padding: 32px;
$container-desktop-padding: 32px;
$container-mobile-padding: 24px;

$site-header-height: var(--site-header-height, 0);

/***************************************
    Breakpoints
****************************************/

/**
These can be used to scale down some values.
eg. desktop-padding: 120px; -> laptop-padding: (desktop-padding * laptop-desktop-ratio);
 */
$laptop-desktop-ratio: 0.8; // laptop-width / desktop-width = 1024 / 1280
$tablet-desktop-ratio: 0.6; // tablet-width / desktop-width = 768 / 1280

//sidebarmenu
$sidemenu-background-color: $color-brand-primary;
