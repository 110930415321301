$subtitle: #00050f;
$breadcrumb: #626d83;
$breadcrumb-hover: #7986a1;
$title-active-label: #1cae56;
$title-active-label-background: rgba(28, 174, 86, 0.16);

.page-title-section {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-end;
	justify-content: space-between;
	margin-bottom: 40px;
	background-color: #fff;

	@include breakpoint(mobile) {
		flex-direction: column;
		align-items: flex-start;
	}

	&-sticky {
		position: sticky;
		padding: 15px 0;
		top: 0;
		z-index: 100;
	}

	h1 {
		@extend %flex-center;
		margin: 0;
		@include font-size(32px, 48px);
		font-weight: $font-weight-medium;
	}

	h2 {
		@extend %flex-center;
		margin: 0;
		@include font-size(24px, 32px);
		font-weight: $font-weight-medium;

		@include breakpoint(mobile) {
			margin-bottom: 12px;
		}
	}

	.title-wrapper {
		margin-inline-end: 20px;

		a {
			@extend %flex-center;
			text-decoration: none;
			transition: color 0.3s ease-in-out, fill 0.3s ease-in-out;
			color: $breadcrumb;
			@include font-size(14px, 18px);

			&:hover {
				color: $breadcrumb-hover;

				svg {
					fill: $breadcrumb-hover;
				}
			}

			svg {
				fill: $breadcrumb;
				margin-right: 4px;
			}
		}

		& > a {
			margin-bottom: 10px;
		}

		svg {
			width: 10px;
			height: 10px;
		}

		&--status {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			align-items: center;
		}
	}

	.buttons-wrapper {
		@extend %flex-center;
		flex-wrap: wrap;

		.button {
			display: inline-flex;
			margin-top: 20px;
			margin-right: 12px;

			&:last-child {
				margin-right: 0;
			}
		}
	}
}

.breadcrumbs {
	margin-bottom: 10px;

	ul {
		@extend %list-reset;
		@extend %flex-center;

		li + li {
			margin-left: 5px;
			position: relative;
			display: inline-flex;
			align-items: center;

			&:before {
				content: '/';
				padding-right: 5px;
				color: $breadcrumb;
			}
		}

		a {
			margin-bottom: 0;
		}
	}
}

.subtitle-wrapper {
	display: flex;
	align-items: flex-start;
	margin-bottom: 16px;

	@include breakpoint(mobile) {
		flex-direction: column;
	}

	&.no-margin {
		margin: 0;
	}

	h3 {
		@include font-size(18px, 24px);
		color: $subtitle;
		font-weight: bold;
	}

	h4 {
		@include font-size(16px, 21px);
		color: $subtitle;
		font-weight: $font-weight-medium;
	}

	.button {
		margin-left: auto;
		display: inline-flex;
		text-decoration: none;
	}

	.single-info-item {
		margin-left: auto;
		margin-bottom: 0;
		font-weight: $font-weight-medium;
	}

	&.subtitle-with-text {
		flex-direction: column;
		max-width: 360px;

		h3 {
			margin-bottom: 4px;
		}

		p {
			color: $text-color-light;
			@include font-size(14px, 20px);
		}
	}

	&.subtitle-with-author {
		margin-bottom: 0;
	}

	.buttons-wrapper {
		margin-left: auto;
		@include breakpoint(mobile) {
			margin-left: 0;
			margin-top: 16px;
			margin-bottom: 24px;
			width: 100%;

			.button {
				width: 100%;
				max-width: 100%;

				& + .button {
					margin-top: 16px;
				}
			}
		}
	}
}

.page-subtitle-section {
	margin-bottom: 24px;

	&.no-margin {
		margin: 0;
	}

	.empty {
		@include font-size(14px, 18px);
		color: $text-color-light;
	}

	table {
		border-collapse: collapse;

		td {
			border-bottom: 1px solid $table-border-color;
			padding: 8px 0;

			&:first-child {
				padding-right: 32px;
			}
		}

		tr:last-child td {
			border-bottom: none;
		}
	}
}
