/**
  * The split() method turns a String into an array of strings,
  * by separating the string at each instance of a specified separator string.
 */
@function split($string, $separator) {
	$split-arr: ();
	$index: str-index($string, $separator);
	@while $index != null {
		$item: str-slice($string, 1, $index - 1);
		$split-arr: append($split-arr, $item);
		$string: str-slice($string, $index + 1);
		$index: str-index($string, $separator);
	}
	$split-arr: append($split-arr, $string);
	@return $split-arr;
}
