$status-label-orange-color: #e86825 !default;
$status-label-orange-background-color: #ffebe1 !default;
$status-label-red-color: #c84603 !default;
$status-label-red-background-color: #ffe8e8 !default;
$status-label-add-color: #63b98a !default;
$status-label-add-background-color: #e7f2e3 !default;

.status-label {
	height: 24px;
	padding: 4px 8px;
	font-size: 12px;
	letter-spacing: 0;
	text-transform: capitalize;

	&--orange {
		color: $status-label-orange-color;
		background-color: $status-label-orange-background-color;
	}

	&--green {
		color: $status-label-add-color;
		background-color: $status-label-add-background-color;
	}

	&--red {
		color: $status-label-red-color;
		background-color: $status-label-red-background-color;
	}
}
