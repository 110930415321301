$admin-button-font-weight: 500 !default;

//Tertiary button colors red @todo
$tertiary-button-color-red: #ffffff;
$tertiary-button-border-color-red: #f76363;
$tertiary-button-text-color-red: #f76363;
$tertiary-button-color-hover-red: #fddada;
$tertiary-button-color-active-red: #fddada;

.button {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	max-width: 440px;
	height: 46px;
	padding: 0 16px;
	@include font-size(16px, 24px);
	font-weight: $admin-button-font-weight;
	text-decoration: none;
	transition: background 0.3s ease-in-out;

	&:disabled,
	&.disabled {
		opacity: $primary-button-disabled;
		cursor: initial;
	}

	&.large {
		width: 100%;
		max-width: 100%;

		&.margin-bottom {
			margin-bottom: 24px;
		}
	}
}

.button--primary {
	@extend %reset-button;
	background: $primary-button-bg;
	color: $color-white;

	&:hover {
		background: $primary-button-bg-hover;
	}

	&:active {
		background: $primary-button-bg-active;
	}
}

.button--gray {
	@extend %reset-button;
	background: $gray-button-bg;
	color: $gray-button-color;

	&:hover {
		background: $gray-button-bg-hover;
	}

	&:active {
		background: $gray-button-bg-active;
	}
}

%button--underline {
	@extend %reset-button;
	border-radius: 0;
	border-bottom: 1px solid $button-underline-color;
	height: $button-underline-height;
	padding: 0 0 2px;
	color: $button-underline-color;
	font-size: 16px;
	line-height: 1.4;
	transition: border 0.2s ease-in-out;
}

.button--underline {
	@extend %button--underline;

	&:hover,
	&:focus {
		border-bottom-color: $button-underline-hover-color;
		color: $button-underline-hover-color;

		svg {
			fill: $button-underline-hover-color;
		}
	}

	&:active {
		color: $button-underline-active-color;

		svg {
			fill: $button-underline-active-color;
		}
	}

	&[disabled],
	&.disabled {
		border-bottom-color: $button-underline-disable-color;
		color: $button-underline-disable-color;
	}

	svg {
		margin-inline-end: 2px;
		fill: $button-underline-svg-color;
		width: 10px;
		height: 10px;
	}

	&.button--small {
		height: $button-underline-small-height;
		font-size: 16px;
	}

	&-secondary {
		&,
		&:hover,
		&:focus {
			border-bottom-color: $color-brand-secondary;
			color: $color-brand-secondary;
		}
	}
}

.button--primary-inverted {
	@extend %reset-button;
	background: $primary-inverted-button-bg;
	color: $primary-inverted-button-color;
	border: 1px solid $primary-inverted-border-color;

	&:hover {
		background: $primary-inverted-button-hover-bg;
	}

	&:active {
		background: $primary-inverted-button-active-bg;
	}
}

.button__tertiary-red {
	@extend %reset-button;
	background: $tertiary-button-color-red;
	color: $tertiary-button-text-color-red;
	border: 1px solid $tertiary-button-border-color-red;

	&:hover {
		background: $tertiary-button-color-hover-red;
	}

	&:active {
		background: $tertiary-button-color-active-red;
	}
}

.buttons-wrapper__in-row {
	margin-top: 24px;

	.button {
		&:last-of-type {
			margin-left: 16px;
		}

		&:first-child {
			margin-left: 0;
		}
	}
}

.margin {
	margin-bottom: 10px;
	margin-top: 24px;
}

/**
 * Underline button but with a span. Used for dropdown with chevron icon.
 */

.button-underline-with-icon {
	@extend %reset-button;

	.button-underline-with-icon__text {
		@extend %button--underline;
	}

	&:hover,
	&:focus {
		.button-underline-with-icon__text {
			border-bottom-color: $button-underline-hover-color;
			color: $button-underline-color;
		}
	}

	&[disabled] {
		.button-underline-with-icon__text {
			border-bottom-color: $button-underline-disable-color;
			color: $button-underline-disable-color;
		}
	}

	&.button--small {
		height: auto;
		padding: 0;

		.button-underline-with-icon__text {
			height: $button-underline-small-height;
			font-size: 16px;
		}
	}
}
