// Needs refactoring - do it like on platform
@mixin breakpoint($point) {
	@if $point == desktop {
		@media (min-width: 1441px) {
			@content;
		}
	} @else if $point == laptop {
		@media (max-width: 1440px) {
			@content;
		}
	} @else if $point == tablet-landscape {
		@media (max-width: 1279px) {
			@content;
		}
	} @else if $point == tablet-portrait {
		@media (max-width: 1023px) {
			@content;
		}
	} @else if $point == phablet {
		@media (max-width: 767px) {
			@content;
		}
	} @else if $point == mobile {
		@media (max-width: 567px) {
			@content;
		}
	} @else if $point == min-mobile {
		@media (min-width: 480px) {
			@content;
		}
	}
}
