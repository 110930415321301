/**
 * Check if RTL is enabled.
 */
@function _is-rtl() {
	@return $global-text-direction == 'rtl';
}

@function _isFlippable($side) {
	@return $side == 'left' or $side == 'right';
}

/**
 * Invert side of the margin.
 */
@function _invert($side) {
	@if (_isFlippable($side)) {
		@return if($side == 'left', 'right', 'left');
	}

	@return $side;
}

/**
 * If RTL Mode.
 */
@mixin rtl() {
	@if (_is-rtl()) {
		@content;
	}
}

/**
 * Reverse row for flex items
 */
@mixin row() {
	@include rtl {
		//flex-direction: row-reverse;
	}
}

/**
 * Add spacing to the right side
 */
@mixin spacing($side, $size, $important: null) {
	$side: if(_is-rtl(), _invert($side), $side);

	margin-#{$side}: $size $important;
}

/**
 * Add padding to the right side
 */
@mixin padding($side, $size, $important: null) {
	$side: if(_is-rtl(), _invert($side), $side);

	padding-#{$side}: $size $important;
}

@function flip($side) {
	@return unquote(_invert($side));
}

/// Function for search entire string and replace the string we need
/// Replace `$search` with `$replace` in `$string`
/// @author Kitty Giraudel
/// @param {String} $string - Initial string
/// @param {String} $search - Substring to replace
/// @param {String} $replace ('') - New value
/// @return {String} - Updated string
@function str-replace($string, $search, $replace: '') {
	$index: str-index($string, $search);

	@if $index {
		@return str-slice($string, 1, $index - 1) + $replace +
			str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
	}

	@return $string;
}

/**
	* Given a property flips its side for RLT.
	* @example
	*
	* @include flip(border-left, 1px solid red)
	* // => border-right: 1px solid red;
	*
	* @include flip(border-top-left-radius, 1px)
	* // => border-top-right-radius: 1px;
	*
	* @include flip(border-top, 1px solid red)
	* // => border-top: 1px solid red;
 */
@mixin flip($property, $value) {
	@include rtl {
		$left-index: str-index($property, 'left');
		$right-index: str-index($property, 'right');
		@if ($left-index) {
			$property: str-replace($property, 'left', 'right');
		}

		@if ($right-index) {
			$property: str-replace($property, 'right', 'left');
		}

		// Check if the value is of the property can be inverted.
		// @example: float, text-align
		@if (_isFlippable($value)) {
			$value: unquote(_invert($value));
		}
	}

	#{$property}: $value;
}
