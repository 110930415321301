.flex {
	display: flex !important;
}

.flex-column {
	display: flex !important;
	flex-direction: column !important;
}

.flex-center {
	display: flex !important;
	align-items: center !important;
}

.flex-jc-center {
	display: flex !important;
	justify-content: center !important;
}

.jc-center {
	justify-content: center !important;
}

.flex--spread {
	justify-content: space-between !important;
}

.flex-wrap {
	display: flex;
	flex-wrap: wrap;
}

.flex--no-shrink {
	flex-shrink: 0;
}

.flex-1 {
	flex: 1 !important;
}

.flex-2 {
	flex: 2;
}

$breakpoints: (tablet);

@for $i from 1 through 4 {
	.order-#{$i} {
		order: $i;
	}
}

@each $breakpoint in $breakpoints {
	@for $i from 1 through 4 {
		@include media-breakpoint-up($breakpoint) {
			.order-#{$breakpoint}-#{$i} {
				order: $i;
			}
		}
	}
}
