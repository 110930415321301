$input-font-weight: 500 !default;

.fields-wrapper__inline {
	display: flex;
	align-items: baseline;
	max-width: 440px;
	margin-top: 16px;

	.form-group {
		flex-grow: 1;
		margin-top: 0;
	}

	.form-group + .form-group {
		margin-top: 0;
		margin-left: 24px;
	}

	.add-more-list__button {
		margin-left: 12px;
	}

	@include breakpoint(phablet) {
		flex-direction: column;

		.form-group {
			max-width: 100%;

			& + .form-group {
				margin-top: 16px;
				margin-left: 0;
			}
		}

		.add-more-list__button {
			margin-top: 16px;
			margin-left: 0;
		}
	}
}

.form-section {
	margin-top: 15px;

	h4 {
		@include font-size(18px, 24px);
		font-weight: $font-weight-medium;
	}
}

.form-group {
	width: 100%;
	display: flex;
	flex-direction: column;
	margin-top: 16px;

	@include breakpoint(tablet-portrait) {
		.list-item {
			display: flex;
		}
	}

	& + .form-group {
		margin-top: 16px;
	}

	&__select-wrapper {
		position: relative;
		max-width: 440px;

		&:before {
			pointer-events: none;
			content: '';
			// background-image: url('../../../../icons/base/icons/icon-arrow-orange.svg');
			background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='%23e86825' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M.166 4.611L4.749.161a.578.578 0 01.8 0l.535.52a.538.538 0 01.001.775L2.453 5l3.632 3.544c.22.214.22.562-.001.776l-.535.52a.578.578 0 01-.8 0L.166 5.387a.538.538 0 010-.777z'/%3E%3C/svg%3E");
			position: absolute;
			right: 12px;
			top: 50%;
			width: 10px;
			height: 10px;
			transform: translateY(-50%) rotate(-90deg);
		}

		option[disabled] {
			display: none;
		}
	}

	input,
	select {
		height: 40px;
	}

	input,
	textarea,
	select {
		@include font-size(14px, 27px);
		width: 100%;
		max-width: 440px;
		border: 1px solid $input-border-color;
		padding: 6px 12px 6px 12px;
		background: none;
		color: $input-color;
		font-weight: $input-font-weight;
		outline: none;
		transition: all 0.3s ease;

		&::placeholder {
			color: $input-placeholder-color;
		}

		&:hover {
			border: 1px solid $input-border-hover-color;
		}

		&:focus {
			border: 1px solid $input-border-focus-color;
		}

		&:disabled {
			color: $input-color-disabled;
			border: 1px solid $input-disable-border-color;
			background-color: $input-background-color-disabled;
		}
	}

	.text-editor {
		width: 100%;
		max-width: 440px;
	}

	input[type='number'] {
		-moz-appearance: textfield;
		appearance: textfield;
		margin: 0;

		&::-webkit-inner-spin-button,
		&::-webkit-outer-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}
	}

	input[type='date'],
	input[type='time'] {
		&::-webkit-inner-spin-button {
			display: none;
		}

		&::-webkit-calendar-picker-indicator {
			opacity: 0;
		}
	}

	textarea {
		display: block;
		resize: none;
		min-height: 130px;
	}

	select {
		-webkit-appearance: none;
		-moz-appearance: none;
		@include font-size(14px, 17px);
	}

	&--error {
		text-align: left;

		input,
		textarea,
		select {
			border-color: $input-error-text-color;
		}

		.input-error {
			color: $input-error-text-color;
			font-weight: $font-weight-medium;
			display: inline-block;
			margin-top: 4px;
			@include font-size(14px, 24px);
		}
	}
}

.form-label {
	color: $input-label-color;
	@include font-size(14px, 24px);
	display: inline-flex;
	margin-bottom: 4px;
	font-weight: $font-weight-medium;

	&.required:after {
		content: '*';
		color: $input-error-text-color;
		margin-left: 5px;
	}
}

span.info-message {
	display: inline-block;
	margin-top: 6px;
	@include font-size(13px, 19px);
}

.form-group__select-wrapper svg {
	fill: $platform-secondary-text-color;
	cursor: pointer;

	&:hover {
		fill: $platform-text-color;
	}
}
