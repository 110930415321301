.overview-wrapper {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 16px;
	grid-auto-rows: 1fr;
	margin-bottom: 32px;

	@include breakpoint(tablet-portrait) {
		grid-template-columns: repeat(2, 1fr);
	}

	@include breakpoint(phablet) {
		grid-template-columns: repeat(1, 1fr);
	}

	.overview-box {
		max-width: 100%;
		border: 1px solid #e6e8eb;
		border-radius: 4px;
		padding: 20px 16px;
		@extend %flex-center;

		.overview-box__content {
			margin-left: 16px;
			@extend %flex-column;
			justify-content: space-between;

			h4 {
				@include font-size(22px, 30px);
				font-weight: 700;
				color: $overview-box-heading-color;
			}

			p {
				@include font-size(14px, 19px);
				color: $overview-box-text-color;
			}
		}

		svg {
			width: 48px;
			height: 48px;
		}
	}
}
