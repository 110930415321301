.w-download-file {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-top: 12px;
	margin-bottom: 16px;
	padding: 5px 16px;
	max-width: 440px;
	width: 100%;
	height: 56px;
	background-color: $download-file-bg-color;
	font-weight: $font-weight-medium;
	text-decoration: none;
	cursor: initial;
	transition: 0.3s background-color ease-in-out;

	&:hover {
		background-color: $download-file-hover-bg-color;
	}

	svg {
		width: 20px;
		height: 20px;

		&:first-child {
			margin-right: 10px;
		}
	}

	.download-icon {
		fill: $download-file-svg-color;
	}

	button,
	a {
		cursor: pointer;
		margin-left: auto;
		@extend %reset-button;

		svg:first-child {
			margin-right: 0;
		}
	}
}
