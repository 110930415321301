@import '~litepicker/dist/css/litepicker.css';

$litepicker-month-width: var(--litepicker-month-width);
$litepicker-font-size: 16px;
$litepicker-month-item-padding: 24px;

$litepicker-border-color: $color-brand-primary !default;
$litepicker-box-shadow: 3px 3px 16px rgba(0, 0, 0, 0.16) !default;

.litepicker,
.litepicker[data-plugins*='multiselect'] {
	margin: 8px 0;
	font-size: $litepicker-font-size;
	font-family: inherit;

	.container__months {
		border: 1px solid $litepicker-border-color;
		box-shadow: $litepicker-box-shadow;
		border-radius: 0;
		width: calc(#{$litepicker-month-width} + #{$litepicker-month-item-padding} * 2);

		.month-item-name {
			text-transform: capitalize;
		}
	}

	.container__months.columns-2 {
		width: calc(#{$litepicker-month-width} * 2 + #{$litepicker-month-item-padding} * 4);
	}

	.container__months .month-item-header {
		padding-top: 0;
	}

	.container__months .month-item-weekdays-row > div {
		display: flex;
		align-items: center;
		justify-content: center;

		height: var(--litepicker-day-width);

		font-size: $litepicker-font-size;
		font-weight: 400;
		text-transform: capitalize;
	}

	.container__months .month-item {
		padding: $litepicker-month-item-padding;
	}

	.container__days .day-item.is-locked {
		text-decoration: line-through;
	}

	.container__days .day-item {
		display: flex;
		align-items: center;
		justify-content: center;

		width: var(--litepicker-day-width);
		height: var(--litepicker-day-width);
		margin: 4px 0;
		padding: 0;
		border-radius: 0;

		&:hover {
			cursor: pointer;
		}

		&.is-locked:hover {
			cursor: not-allowed;
		}

		&:not(.is-locked):hover {
			font-weight: normal;
			color: var(--litepicker-day-today-color-hover);

			&::after {
				border-radius: 0;
				// TODO: Ask about this border
				box-shadow: inset 0 0 0 1px var(--litepicker-day-color-hover);
				width: var(
					--litepicker-multiselect-day-size-selected
				); // Funny box-shadow/outline/border for 27px;
				height: var(
					--litepicker-multiselect-day-size-selected
				); // Funny box-shadow/outline/border for 27px;
			}
		}

		&.is-selected,
		&.is-start-date,
		&.is-end-date,
		&.is-start-date.is-end-date {
			border-radius: 0;

			&.is-flipped {
				border-radius: 0;
			}

			&:hover {
				font-weight: normal;
				color: $color-white;
			}

			&::after {
				border-radius: 0;
				width: var(--litepicker-multiselect-day-size-selected);
				height: var(--litepicker-multiselect-day-size-selected);
			}
		}
	}
}

.litepicker[data-plugins*='multiselect'] .day-item.is-selected:hover {
	background-color: var(--litepicker-highlighted-day-color-bg);
	color: var(--litepicker-highlighted-day-color);
}

.litepicker--inline {
	.litepicker {
		margin: initial;
		// since the calendar is inline, he shouldn't have z-index value
		z-index: 0 !important;

		.container__months {
			box-shadow: none;
			border: none;
			width: $litepicker-month-width;
		}

		.container__months.columns-2 {
			width: calc(#{$litepicker-month-width} * 2 + #{$litepicker-month-item-padding} * 2);

			.month-item + .month-item {
				padding-left: $litepicker-month-item-padding * 2;
			}
		}

		.container__months {
			.month-item {
				padding: 0;
			}
		}
	}
}

.litepicker .container__months .month-item-header .button-previous-month,
.litepicker .container__months .month-item-header .button-next-month {
	cursor: pointer;

	@include rtl {
		transform: rotate(-180deg);
	}
}
