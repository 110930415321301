@import '../functions/functions';

$dropdown-menu-boxshadow: 3px 3px 16px rgba(0, 0, 0, 0.16) !default;
$item-height: 48px;
$item-padding-horizontal: 16px;
$dropdown-item-hover-background: $color-brand-primary !default;
$dropdown-item-hover-color: #fff !default;

.dropdown {
	cursor: pointer;
	line-height: 1;
}

.dropdown-menu {
	display: none;
	position: absolute;
	border: 1px solid $dropdown-border-color;
	box-shadow: $dropdown-menu-boxshadow;
	margin: 0;
	padding: 0;
	min-width: 160px;
	background: #ffffff;
	color: $global-font-color;
	font-size: $global-font-size;
	list-style: none;
	@include z-index('dropdown');
}

.dropdown-menu.active {
	display: block;
}

.dropdown__indicator {
	transition: transform 0.2s ease-in-out;
}

.dropdown__indicator.active {
	transform: rotate(180deg);
}

.dropdown__item {
	display: flex;
	align-items: center;
	width: 100%;
	height: $item-height;
	padding: 0 $item-padding-horizontal;
	@include transition('background-color, color', 0.2s);
	color: $global-font-color;
	font-size: $global-font-size;
	white-space: nowrap;
	text-decoration: none;
	text-align: left;

	&:hover {
		background-color: $dropdown-item-hover-background;
		color: $dropdown-item-hover-color;
		cursor: pointer;
	}
}

@include media-breakpoint-down(tablet) {
	.dropdown__indicator {
		display: none;
	}
	.dropdown--expend-tablet {
		.dropdown__trigger {
			display: none;
		}

		.dropdown-menu {
			display: block;
			position: static;
			background: none;
			border: 0;
			box-shadow: none;
		}

		.dropdown__item {
			text-decoration: underline;
			padding: 0;

			&:hover {
				background-color: transparent;
				color: $global-link-hover-font-color;
			}
		}
	}
}

@include media-breakpoint-down(laptop) {
	.dropdown--expend-laptop {
		.dropdown__trigger {
			display: none;
		}

		.dropdown-menu {
			display: block;
			position: static;
			background: none;
			border: 0;
			box-shadow: none;
		}

		.dropdown__item {
			text-decoration: underline;
			padding: 0;

			&:hover {
				background-color: transparent;
				color: $global-link-hover-font-color;
			}
		}
	}
}
