$sidemenu-color: $color-white;
$sidemenu-toggle-button: $sidemenu-background-color;
$sidemenu-responsive-item--active: $color-brand-secondary;
$sidemenu-item-active-color: $sidemenu-active-color;

.side-menubar {
	background: $sidemenu-background-color;
	display: flex;
	flex-direction: column;
	flex-shrink: 0;
	width: 100%;
	max-width: 240px;

	@include breakpoint(tablet-portrait) {
		display: none;
	}

	h2 {
		transition: opacity 0.3s ease-in-out;
		transition-delay: 0.3s;
	}

	.header {
		padding: 24px 24px 0 24px;
	}

	.logo {
		position: relative;
		transform: translateX(0px);
		transition: all 0.3s ease-in-out;

		img {
			width: 100%;
		}
	}

	h2 {
		text-transform: uppercase;
		@include font-size(11px, 14px);
		color: $text-color-light;
		padding: 24px 24px 0;
		min-height: 56px;
		font-weight: bold;
		letter-spacing: 0.25px;
	}

	&.responsive {
		h2,
		.site-menu__item span,
		.profile-dropdown h3,
		.profile-dropdown .dropdown-icon-arrow {
			opacity: 0;
			transition: opacity 0.3s ease-in-out;
		}

		.site-menu__item span {
			transition-delay: 0s;
		}

		.logo {
			opacity: 0;
			transform: translateX(-65px);
		}

		.button__toggle-sidemenu {
			transform: translateX(-38px) rotate(180deg);
		}

		.profile > div:first-child {
			width: auto;
		}
	}

	@include breakpoint(tablet-portrait) {
		transition: max-width 0.3s ease-in-out;
	}

	&.responsive {
		.site-menu a {
			padding: 0 10px;
		}
		max-width: 44px;
	}
}

.site-menu {
	list-style: none;
	padding: 24px 0 12px;
	overflow-y: auto;

	&__item {
		a,
		button {
			@extend %reset-button;
			@extend %flex-center;
			@include font-size(14px, 19px);
			width: 100%;
			color: $sidemenu-color;
			padding: 0 21px;
			min-height: 56px;
			font-weight: $font-weight-medium;
			border-left: 3px solid transparent;
			text-decoration: none;

			&.active {
				border-left-color: $sidemenu-item-active-color;
				color: $color-white;

				svg {
					fill: $sidemenu-item-active-color;
				}
			}

			&:hover {
				background-color: $sidebar-hover-state-background;
			}

			svg {
				fill: $text-color-light;
				transition: fill 0.4s ease-in-out;
				flex-shrink: 0;
			}
		}
	}
}

//Responsive menu

.responsive-menu {
	display: none;

	&__wrapper {
		display: none;

		form {
			border-top: 1px solid #e6e8eb;
			margin: 0 22px;
		}
	}

	.site-menu__item a {
		padding: 0 21px;
		color: $sidemenu-responsive-items;

		&.active {
			color: $sidemenu-responsive-item--active;
			border-left-color: $sidemenu-responsive-item--active;
		}
	}

	.logout-button {
		@extend %reset-button;
		@extend %flex-center;
		@include font-size(14px, 19px);
		width: 100%;
		color: $sidemenu-responsive-items;
		min-height: 56px;
		font-weight: $font-weight-medium;
		border-left: 3px solid transparent;
		transition: background-color 0.3s ease-in-out, padding 0.3s ease-in-out;

		&:hover {
			background-color: $sidebar-hover-state-background;
		}
	}

	.icon {
		width: 20px;
		height: 20px;
	}

	//this needs color refactoring @todo
	.logo {
		.icon {
			fill: $color-brand-secondary;
			width: 32px;
			height: 32px;
		}
	}

	@include breakpoint(tablet-portrait) {
		margin-bottom: 12px;
		display: flex;
		align-items: center;
		padding: 12px 22px;
		border-bottom: 1px solid #e6e8eb;

		z-index: 1000;
	}

	.button__toggle-responsive-menu {
		@extend %reset-button;
		margin-right: auto;
		display: flex;
		z-index: 10;

		svg {
			fill: $sidemenu-toggle-button;
		}
	}

	.icon-close {
		display: none;
	}

	&.responsive {
		.responsive-menu__wrapper {
			display: block;
			position: absolute;
			background-color: $color-white;
			width: 100%;
			height: 100%;
			z-index: 1;
			left: 0;
			top: 0;
			padding-top: 60px;
		}

		.icon-close {
			display: block;
		}

		.icon-menu {
			display: none;
		}
	}
}

.profile {
	flex: 1;
	display: flex;
	align-items: flex-end;
	margin-top: 0;
	margin-bottom: 24px;

	.site-menu {
		flex: 1;
		padding: 0;
		margin: 0;
	}
}
