//status indicators
$status-indicator-color-gray: #c9c9c9 !default;
$status-indicator-color-red: #c80303 !default;
$status-indicator-color-yellow: #ffb31b !default;
$status-indicator-color-green: #63b98a !default;

.status-circle {
	display: inline-block;
	height: 8px;
	width: 8px;
	border-radius: 50%;
	background-color: $status-indicator-color-gray;

	&--red,
	&.missing {
		background-color: $status-indicator-color-red;
	}

	&--yellow,
	&.requested {
		background-color: $status-indicator-color-yellow;
	}

	&--green,
	&.received {
		background-color: $status-indicator-color-green;
	}
}

.status-circle-label {
	display: flex;
	align-items: center;

	.status-circle + * {
		margin-inline-start: 8px;
	}
}
