$root: '.add-more-list';

#{$root} {
	&__button {
		background: none;
		border: 0;
		color: $input-close-color;
		font-size: 18px;
		cursor: pointer;

		// Let the click through, so it's passed to the button elem.
		.icon {
			pointer-events: none;
			width: 22px;
			height: 22px;
		}
	}
}
