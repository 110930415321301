.modal__overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.6);
	display: flex;
	justify-content: center;
	align-items: center;
	@include z-index('modal');
}

.modal__container {
	position: relative;
	max-height: 100%; // Can't use vh because of Safari on IOS
	background-color: #fff;
	padding: 30px 24px;
	max-width: 640px;
	width: 100%;
	overflow-y: auto;
	box-sizing: border-box;
}

.modal__header {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	margin-bottom: 16px;
}

.modal__title {
	margin: 0;
}

.modal__close {
	@extend %reset-button;
	margin-inline-start: 32px;
	font-size: 32px;
	line-height: 0;
}

.modal__footer {
	padding: 0;
	margin-top: 64px;
}

.modal__action {
	justify-content: center;
	min-width: 168px;
}

.modal--xl {
	.modal__container {
		max-width: 988px;
	}
}

@include tablet {
	.modal__body {
		width: 80%;
	}
}

@include laptop {
	.modal__container {
		padding: 64px 32px;
	}

	.modal__content {
		padding-right: 64px;
	}

	.modal--sm {
		.modal__container {
			padding: 64px;
		}
	}
}

/**************************\
  Animations
\**************************/

@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes fadeOut {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}

.modal {
	display: none;
}

.modal.is-open {
	display: block;
}

.modal[aria-hidden='false'] .modal__overlay {
	animation: fadeIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.modal[aria-hidden='false'] .modal__container {
	animation: fadeIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.modal[aria-hidden='true'] .modal__overlay {
	animation: fadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.modal[aria-hidden='true'] .modal__container {
	animation: fadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.modal .modal__container,
.modal .modal__overlay {
	will-change: transform;
}
