.modal-two-columns {
	display: grid;
	grid-template-columns: repeat(1, minmax(max-content, 1fr));
	column-gap: 40px;
	row-gap: 24px;
}

.modal__info-item__title {
	font-size: 12px;
	font-weight: $font-weight-medium;
	text-transform: uppercase;
	color: $color-orange-primary;
}

.modal__info-item__description {
	margin-top: 8px;
}

@include tablet {
	.modal-two-columns {
		grid-template-columns: repeat(2, minmax(max-content, 1fr));
	}
}
