$single-item-not-available-color: #afafaf !default;

.single-info-item {
	margin-bottom: 16px;
	max-width: 800px;

	//+ .single-info-item {
	//    padding-left: 40px;
	//}

	h4 {
		margin-top: 0;
		margin-bottom: 8px;
		@include font-size(14px, 24px);
		text-transform: uppercase;
		color: $info-item-heading-color;
		font-weight: $font-weight-medium;
		letter-spacing: 1px;
	}

	p {
		margin: 0;
		color: $info-item-p-color;
		@include font-size(16px, 24px);

		&.not-available {
			color: $single-item-not-available-color;
		}
	}

	ul,
	ol {
		margin: 0;
		padding: 0 24px;
	}

	.editor-html {
		h4 {
			@include font-size(18px, 28px);
			letter-spacing: 0.5px;
			text-transform: none;
			color: $info-item-heading-color;
		}

		p,
		ul,
		ol {
			margin-bottom: 8px;
		}

		a {
			color: $info-item-p-color;
		}
	}
}

img {
	max-width: 344px;
}

.items-wrapper__inline {
	display: flex;
	gap: 16px;
}
