$active-btn-bg: $text-editor-btn-bg-color;
$rte-min-height: 114px !default;
$rte-height: 200px !default;
$rte-line-height: inherit !default;

.text-editor {
	border: 1px solid $text-editor-color;
	padding: 12px;
	min-height: $rte-min-height;

	&.text-editor--lg {
		min-height: $rte-height;
		.ProseMirror {
			height: $rte-height;
		}
	}

	.rte-menu-bar {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		padding-top: 8px;

		.rte-menu-bar--btn {
			display: flex;
			align-items: center;
			justify-content: center;
			border: none;

			margin-inline-end: 8px;
			padding: 2px 0;
			width: 20px;
			height: 20px;
			background: $color-white;
			color: $text-editor-btn-color;

			font-size: 16px;
			-webkit-appearance: none;
			cursor: pointer;

			&:last-child {
				margin-inline-end: 0;
			}

			&.active {
				background: $active-btn-bg;
				color: $text-editor-btn-color;
			}
		}
	}

	.rte-menu-bar--extended {
		display: flex;
		align-items: center;
		flex-wrap: wrap;

		border-inline-start: 1px solid #bacbc2;
		padding-inline-start: 8px;
	}

	.ProseMirror {
		height: $rte-min-height;
		line-height: $rte-line-height;
		overflow-y: auto;
		outline: none;

		p,
		h4,
		ul {
			margin-top: 16px;
			margin-bottom: 0;
		}

		a {
			text-decoration: underline;
			cursor: pointer;
			transition: color 0.2s ease-in-out;
		}

		// scrollbar
		&::-webkit-scrollbar {
			width: 12px;
		}
		&::-webkit-scrollbar-track {
			background: $color-gray-light;
		}
		&::-webkit-scrollbar-thumb {
			background-color: $color-green-light;
			border-radius: 20px;
		}
	}
}
