/*** Checkbox style ***/

.control {
	display: block;
	position: relative;
	padding-left: 34px;
	cursor: pointer;
	@include font-size(15px, 23px);
	color: $checkbox-label;

	input {
		position: absolute;
		z-index: -1;
		opacity: 0;

		&:focus ~ .control__indicator {
			background: none;
		}

		&:checked ~ .control__indicator {
			background: $checkbox-background-color-checked;

			&:after {
				display: block;
			}
		}
	}
}

.control__indicator {
	margin-top: 0 !important;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: 0;
	height: 24px;
	width: 24px;
	background: none;
	border: 1px solid $checkbox-border;

	&:after {
		content: '';
		position: absolute;
		display: none;
	}
}

.control--checkbox {
	.control__indicator:after {
		left: 7px;
		top: 1px;
		width: 6px;
		height: 14px;
		border: solid $checkbox-checkmark;
		border-width: 0 2px 2px 0;
		transform: rotate(45deg);
	}
}

.deselect.control--checkbox {
	position: absolute;
	bottom: 70px;
	margin: 0px 16px;

	&.control--checkbox .control__indicator:after {
		left: 6px;
		top: 3px;
		height: 6px;
		width: 0;
		border: 1px solid $color-white;
		transform: rotate(90deg);
	}
}

/*** Radio button style ***/
.control--radio {
	.control__indicator {
		border-radius: 50%;

		&:after {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 8px;
			height: 8px;
			border: 0;
		}
	}

	input:checked ~ .control__indicator {
		border-color: $checkbox-background-color-checked;
		border-radius: 50%;
		background-color: $checkbox-background-color-checked;

		&:after {
			background-color: $checkbox-background-color-checked;
			border-radius: 50%;
		}
	}
}

.radio-checkbox-group {
	&__inline {
		margin-top: 16px;
		@extend %flex-center;

		.control + .control {
			margin-left: 16px;
		}

		@include breakpoint(phablet) {
			flex-direction: column;
			align-items: flex-start !important;

			.control + .control {
				margin-left: 0;
				margin-top: 16px;
			}
		}
	}

	&__multiple-lines {
		@extend %flex-column;

		.control + .control {
			margin-top: 16px;
		}
	}
}
