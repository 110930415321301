$button-border-radius: 500px !default;
$button-height: 60px !default;
$button-height-small: 36px !default;
$button-padding-small: 24px !default;
$button-underline-height: 33px;
$button-underline-small-height: 27px;

$button-primary-background: $color-brand-secondary !default;
$button-primary-hover-background: $color-brand-primary !default;
$button-primary-focus-background: $color-brand-primary !default;
$button-primary-disable-background: $color-orange-medium !default;

$button-secondary-color: $color-brand-primary !default;
$button-secondary-border-color: $color-brand-primary !default;
$button-secondary-background: transparent !default;
$button-secondary-hover-background: $color-brand-primary !default;
$button-secondary-disable-background: transparent !default;
$button-secondary-disable-color: $color-green-medium !default;

$button-secondary-active-border-color: $color-brand-primary !default;
$button-secondary-active-background: $color-brand-primary !default;

$button-round-line-height: 24px;
$button-round-size: 60px;
$button-round-icon-size: 24px;
$button-round-margin: 14px;
$button-round-padding-top-bottom: 18px;
$button-round-padding-start: $button-round-size + $button-round-margin;
$button-round-padding-end-hover: 2 * $button-round-icon-size + $button-round-margin;
$button-round-padding-end: 24px;

$button-primary-text-color: $color-brand-secondary;
$button-primary-text-hover-color: $color-brand-primary;
$button-primary-text-disabled: $color-orange-medium;

%reset-button {
	background: none;
	border: 0;
	margin: 0;
	cursor: pointer;
	outline: none;
	padding: 0;
	line-height: 1;
	color: inherit;
}

.button--reset {
	@extend %reset-button;
}
