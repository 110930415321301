$site-breakpoints: (
	pico: 375px,
	small: 600px,
	tablet: 768px,
	laptop: 1024px,
	desktop: 1280px,
	large: 1400px,
);

// Name of the next breakpoint, or null for the last breakpoint.
//
//    >> breakpoint-next(small)
//    tablet
//    >> breakpoint-next(small, (small: 600px, tablet: 768px, laptop: 1024px, desktop: 1200px, large: 1400px))
//    tablet
//    >> breakpoint-next(sm, $breakpoint-names: (xs sm md lg xl))
//    md
@function breakpoint-next(
	$name,
	$breakpoints: $site-breakpoints,
	$breakpoint-names: map-keys($breakpoints)
) {
	$n: index($breakpoint-names, $name);
	@if not $n {
		@error "breakpoint `#{$name}` not found in `#{$breakpoints}`";
	}
	@return if($n < length($breakpoint-names), nth($breakpoint-names, $n + 1), null);
}

// Minimum breakpoint width. Null for the smallest (first) breakpoint.
//
//    >> breakpoint-min(small, (small: 600px, tablet: 768px, laptop: 1024px, desktop: 1200px, large: 1400px))
//    576px
@function breakpoint-min($name, $breakpoints: $site-breakpoints) {
	$min: map-get($breakpoints, $name);
	@return if($min != 0, $min, null);
}

// Maximum breakpoint width.
// The maximum value is reduced by 0.02px to work around the limitations of
// `min-` and `max-` prefixes and viewports with fractional widths.
// See https://www.w3.org/TR/mediaqueries-4/#mq-min-max
// Uses 0.02px rather than 0.01px to work around a current rounding bug in Safari.
// See https://bugs.webkit.org/show_bug.cgi?id=178261
//
//    >> breakpoint-max(tablet, (small: 600px, tablet: 768px, laptop: 1024px, desktop: 1200px, large: 1400px))
//    767.98px
@function breakpoint-max($name, $breakpoints: $site-breakpoints) {
	$max: map-get($breakpoints, $name);
	@return if($max and $max > 0, $max - 0.02, null);
}

// Media of at least the minimum breakpoint width. No query for the smallest breakpoint.
// Makes the @content apply to the given breakpoint and wider.
@mixin media-breakpoint-up($name, $breakpoints: $site-breakpoints) {
	$min: breakpoint-min($name, $breakpoints);
	@if $min {
		@media (min-width: $min) {
			@content;
		}
	} @else {
		@content;
	}
}

// Media of at most the maximum breakpoint width. No query for the largest breakpoint.
// Makes the @content apply to the given breakpoint and narrower.
@mixin media-breakpoint-down($name, $breakpoints: $site-breakpoints) {
	$max: breakpoint-max($name, $breakpoints);
	@if $max {
		@media (max-width: $max) {
			@content;
		}
	} @else {
		@content;
	}
}

// Media that spans multiple breakpoint widths.
// Makes the @content apply between the min and max breakpoints
@mixin media-breakpoint-between($lower, $upper, $breakpoints: $site-breakpoints) {
	$min: breakpoint-min($lower, $breakpoints);
	$max: breakpoint-max($upper, $breakpoints);

	@if $min != null and $max != null {
		@media (min-width: $min) and (max-width: $max) {
			@content;
		}
	} @else if $max == null {
		@include media-breakpoint-up($lower, $breakpoints) {
			@content;
		}
	} @else if $min == null {
		@include media-breakpoint-down($upper, $breakpoints) {
			@content;
		}
	}
}

// Media between the breakpoint's minimum and maximum widths.
// No minimum for the smallest breakpoint, and no maximum for the largest one.
// Makes the @content apply only to the given breakpoint, not viewports any wider or narrower.
@mixin media-breakpoint-only($name, $breakpoints: $site-breakpoints) {
	$min: breakpoint-min($name, $breakpoints);
	$next: breakpoint-next($name, $breakpoints);
	$max: breakpoint-max($next, $breakpoints);

	@if $min != null and $max != null {
		@media (min-width: $min) and (max-width: $max) {
			@content;
		}
	} @else if $max == null {
		@include media-breakpoint-up($name, $breakpoints) {
			@content;
		}
	} @else if $min == null {
		@include media-breakpoint-down($next, $breakpoints) {
			@content;
		}
	}
}

// Deprecated syntax below
@mixin small {
	@media (min-width: 640px) {
		@content;
	}
}

@mixin below-tablet {
	@media (max-width: 767px) {
		@content;
	}
}

@mixin tablet {
	@media (min-width: 768px) {
		@content;
	}
}

@mixin below-laptop {
	@media (max-width: 1023px) {
		@content;
	}
}

@mixin laptop {
	@media (min-width: 1024px) {
		@content;
	}
}

@mixin desktop {
	@media (min-width: 1280px) {
		@content;
	}
}

@mixin below-desktop {
	@media (max-width: 1279px) {
		@content;
	}
}

@mixin large {
	@media (min-width: 1400px) {
		@content;
	}
}

@mixin between-tablet-laptop {
	@media (min-width: 768px) and (max-width: 1023px) {
		@content;
	}
}
