$tabs-component-border: #d4ddd8;

.tabs {
	margin-top: 32px;
	margin-bottom: 32px;

	ul {
		@extend %list-reset;
		@extend %flex-center;
		border-bottom: 1px solid $tabs-component-border;
		@include breakpoint(mobile) {
			overflow-x: auto;

			li {
				flex: 1 0 auto;
			}
		}

		@include breakpoint(phablet) {
			margin-left: -22px;
			margin-right: -22px;
			padding-right: 22px;
			padding-left: 22px;
		}
	}

	li + li {
		margin-left: 24px;
	}

	a {
		@extend %flex-center;
		padding-bottom: 13px;
		border-bottom: 2px solid transparent;
		text-decoration: none;
		font-weight: $font-weight-medium;
		transition: color 0.3s ease-in-out, border-bottom-color 0.3s ease-in-out;

		&.active {
			border-bottom-color: $tab-active-border-color;
		}

		&:hover {
			border-bottom-color: $tab-active-border-color;
		}
	}
}
