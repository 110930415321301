body {
	margin: 0;
}

.auth {
	background: $auth-background-color;
	padding-top: 128px;

	&__container {
		box-shadow: 0 1px 4px 0 rgba(21, 34, 50, 0.08);
		background: #ffffff;
		padding: 32px;
		width: 100%;
		max-width: 416px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin: auto;
		text-align: center;

		h3 {
			font-weight: $font-weight-medium;
			@include font-size(24px, 32px);
			color: $login-heading-color;
			margin: 16px 0 5px;
		}

		p {
			@include font-size(14px, 18px);
			color: $auth-text-color;
			text-align: center;
			margin-top: 5px;
		}
	}

	form {
		width: 100%;
		max-width: 352px;
		margin-top: 27px;

		button {
			margin-top: 24px;
			margin-bottom: 32px;
			width: 100%;
		}

		input {
			max-width: 352px;
		}
	}

	.success-reset-password {
		width: 100%;
		max-width: 295px;
		text-align: center;
		margin: auto;

		h4 {
			font-weight: bold;
			margin-top: 32px;
			color: #ffffff;
		}
	}
}

.keep-me-login-label {
	text-align: left;
}
