/**
* DAS => Days And Shifts
 */

$treatment-das-calendar-padding: 24px;
$treatment-das-calendar-border-size: 1px !default;
$treatment-das-calendar-border-color: $color-brand-primary !default;
$litepicker-month-width: var(--litepicker-month-width);

$treatment-das-button-reset-color: inherit !default;
$treatment-das-button-reset-font-size: inherit !default;

$treatment-das-shifts-select-width: calc(
	#{$litepicker-month-width} + 2 * #{$treatment-das-calendar-padding} + 2 * #{$treatment-das-calendar-border-size}
);

.v-treatment-das__calendar.litepicker--inline {
	.litepicker {
		.container__months {
			border: $treatment-das-calendar-border-size solid $treatment-das-calendar-border-color;
			padding: $treatment-das-calendar-padding;
		}
	}
}

.v-treatment-das__shifts {
	> * + * {
		margin-top: 16px;
	}
	> *:first-child {
		margin-top: 32px;
	}
	.form-label {
		text-transform: capitalize;
	}
}

.v-treatment-das__shifts-select {
	width: $treatment-das-shifts-select-width;
}

.v-treatment-das__close-button {
	padding: 16px 18px;
	svg {
		color: $treatment-das-button-reset-color;
		font-size: $treatment-das-button-reset-font-size;
	}
}

@include laptop {
	.v-treatment-das__shifts-select {
		min-width: $treatment-das-shifts-select-width;
	}
}
