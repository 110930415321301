$flash-message-heading-color: #ffffff;
$flash-message-content-color: #ffffff;
$flash-message-text: #595c62;

.flash-message {
	border-radius: 4px;
	padding: 16px;
	display: flex;
	align-items: flex-start;
	max-width: 440px;
	margin-bottom: 16px;
	width: 100%;
	border: 1px solid;
	color: #fff;
	position: fixed;
	top: 24px;
	left: 50%;
	transform: translateX(-50%);
	z-index: 1001; // z-index-navigation + 1

	&__error,
	&__success,
	&__warning {
		h3 {
			color: $flash-message-heading-color;
		}

		p {
			color: $flash-message-content-color;
		}

		button {
			svg {
				fill: $flash-message-content-color;
			}
		}
	}

	&__error {
		background-color: $flash-message-error-background;
	}

	&__success {
		background-color: $flash-message-success-background;
	}

	&__warning {
		background-color: $flash-message-warning-background;
	}

	&__content {
		margin-left: 12px;
		margin-right: auto;

		h3 {
			@include font-size(14px, 18px);
			font-weight: bold;
			margin-bottom: 3px;
		}

		p {
			@include font-size(14px, 20px);
			font-weight: 400;
		}
	}

	&__icon {
		position: relative;
		top: 3px;
		flex: none;
	}

	&.hide {
		display: none;
	}

	button {
		@extend %reset-button;
		display: inline-flex;
		position: relative;
		top: 6px;

		svg {
			opacity: 1;
			transition: opacity 0.3s ease-in-out;
		}

		&:hover svg {
			opacity: 0.7;
		}
	}
}
