%heading {
	font-weight: $font-weight-heading;
}

.heading-hero {
	@extend %heading;
	font-size: $header-hero-font-size;
	line-height: 1;
}

.heading-1 {
	@extend %heading;
	font-size: $header-h1-font-size;
}

.heading-2 {
	@extend %heading;
	font-size: $header-h2-font-size;
}

.heading-3 {
	@extend %heading;
	font-size: $header-h3-font-size;
}

.heading-4 {
	@extend %heading;
	color: $heading-4-color;
	font-size: $header-h4-font-size;
	letter-spacing: 3.2px;
	text-transform: uppercase;
}

@include laptop {
	.heading-hero {
		font-size: $header-hero-font-size-desktop;
	}

	.heading-1 {
		font-size: $header-h1-font-size-desktop;
	}

	.heading-2 {
		font-size: $header-h2-font-size-desktop;
	}

	.heading-3 {
		font-size: $header-h3-font-size-desktop;
	}

	.heading-4 {
		font-size: $header-h4-font-size-desktop;
		letter-spacing: 4.5px;
	}
}
