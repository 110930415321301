* {
	box-sizing: border-box;
}

html,
body {
	-webkit-text-size-adjust: 100%; // Fix safari text scaling
	margin: 0;
	padding: 0;
	overflow: hidden;
	height: 100%;
	background-color: #ffffff;
	letter-spacing: -0.25px;
}

.header {
	.logo {
		display: inline-flex;
		text-decoration: none;
	}
}

.hidden,
*[hidden] {
	display: none !important;
}

.layout {
	display: flex;
	width: 100%;
	height: 100%;

	@include breakpoint(tablet-portrait) {
		flex-direction: column;
	}

	.content {
		flex: 1;
		overflow-y: auto;
		overflow-x: hidden;
		position: relative;
		padding-bottom: 60px;

		////@include breakpoint(phablet) {
		//    width: 100%;
		//    max-width: 100%;
		//    transition: transform 0.3s ease-in-out;
		//    flex: 1 0 auto;
		//    transform: translateX(-320px);
		//////}
		//
		//&.responsive {
		//    @include breakpoint(phablet) {
		//        max-width: 100%;
		//        flex: 1 0 auto;
		//        transform: translateX(0);
		//    }
		//}
	}
}

.dashboard-page {
	display: flex;
	flex-direction: column;
	height: 100%;

	.page-content {
		padding: 24px 40px;

		@include breakpoint(phablet) {
			padding: 0 22px 20px;
		}
	}
}

%flex-center {
	display: flex;
	align-items: center;
}

%translate-center {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

%flex-column {
	display: flex;
	flex-direction: column;
}

%flex-row {
	display: flex;
	flex-direction: row;
}

%list-reset {
	padding-left: 0;
	list-style: none;
	margin: 0;
}
