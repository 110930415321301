.upload-file {
	display: inline-flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	position: relative;

	width: 440px;
	max-width: 100%;
	height: 56px;

	cursor: pointer;
	color: $upload-file-color;
	border: 1px dashed $upload-file-border-color;

	input[type='file'] {
		visibility: hidden;
		width: 0;
		height: 0;
		position: absolute;
	}

	&--image {
		.upload-file__create {
			display: block;
			text-align: center;
			width: 100%;
		}

		.upload-file__preview {
			background-color: transparent;
			background-position: center;
			background-size: cover;
			background-repeat: no-repeat;

			display: none;
			width: 100%;
			height: 100%;
			flex-shrink: 0;
		}

		.upload-file__edit {
			display: none;
			position: absolute;
			bottom: 12px;
			right: 12px;
			line-height: 24px;
			font-size: 1rem;
		}
	}

	&--uploaded {
		border: 1px solid $upload-file-border-color;

		.upload-file__create {
			display: none;
		}

		.upload-file__edit {
			display: block;
		}

		.upload-file__preview {
			display: block;
			transform: rotate(0);
		}

		svg {
			width: 24px;
			height: 24px;
		}
	}
}
