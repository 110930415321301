//layout
$platform-text-color: #1c5335;
$platform-secondary-text-color: #e86825;
$text-color-light: #5d7d6a;
$active-state-color: #000000;
$sidebar-hover-state-background: rgba(#ebebeb, 0.3);
$dashboard-header-dropdown-border: #d2d5d9;
$signature-color: #3a564f;
$error-color: #e40000;

$error-border-color: $error-color;
//Labels colors
$label-inactive-color: $error-color;
$label-inactive-background-color: #fff;

$label-help-color: #0036a3;
$label-help-background-color: rgba(0, 54, 163, 0.16);

$label-skipped-color: #626d83;
$label-skipped-background-color: #f0f1f7;

$label-completed-text-color: #ffffff;
