$changed-booking-icon-color: #ffb31b !default;

.table {
	overflow-x: auto;
	width: 100%;
	border-collapse: collapse;

	tr {
		.arrow-up,
		.arrow-down {
			&:after {
				content: '';
				display: inline-block;
				margin-left: 10px;
				width: 0;
				height: 0;
				border-left: 6px solid transparent;
				border-right: 6px solid transparent;
			}
		}

		.arrow-up:after {
			border-bottom: 12px solid $table-arrow-color;
		}

		.arrow-down:after {
			border-top: 12px solid $table-arrow-color;
		}

		th {
			padding: 16px;
			background-color: $table-header-background;
			color: $global-font-color;
			@include font-size(14px, 24px);
			text-align: left;
			font-weight: $font-weight-medium;
			font-variant: tabular-nums;

			&.small-width {
				width: 150px !important;
			}

			a {
				display: inline;
				letter-spacing: 0.25px;
				@include font-size(14px, 24px);
				text-decoration: none;
			}
		}

		td {
			border-bottom: 1px solid $table-border-color;
			@include font-size(14px, 24px);
			padding: 16px;
			font-variant: tabular-nums;

			a:not(.button) {
				text-decoration: none;

				&:hover {
					text-decoration: underline;
				}
			}

			&.primary {
				color: $color-brand-primary;
				font-weight: $font-weight-medium;
			}

			.changed-booking {
				display: grid;
				grid-template-columns: 1fr min-content;
				align-items: center;

				.icon {
					color: $changed-booking-icon-color;
				}
			}
		}
	}

	.button-underline-with-icon .button-underline-with-icon__text {
		color: $table-text-color;
		border-color: $table-text-color;
		font-size: 14px;
	}
}

.table-section {
	margin-top: 3em;

	&__header {
		margin-bottom: 0.5em;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}

.table-wrapper {
	overflow-x: auto;

	.invoice-table {
		.w-15 {
			width: 15%;
		}

		.w-5 {
			width: 5%;
		}

		.w-auto {
			width: auto;

			.form-group input,
			.form-group textarea {
				max-width: 100%;
			}
		}
	}
}

.table-info {
	margin: 1em 0;
	font-size: 14px;
}

.align-right {
	text-align: right !important;
}

.align-top {
	vertical-align: top;
}

.context {
	cursor: help;
}

.table-search-section {
	margin-bottom: 24px;

	@include breakpoint(min-mobile) {
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}

	.dropdown {
		margin-bottom: 15px;

		@include breakpoint(min-mobile) {
			margin-bottom: 0;
		}
	}

	.input {
		background: none;
		border: 0;
		border-radius: 0;
		outline: 0;
		font: inherit;
		color: $input-color;
		width: 100%;
	}

	.input--primary {
		border: 1px solid $input-border-color;
		height: 48px;
		padding: 0 16px;
	}

	.input-with-icon {
		position: relative;
		max-width: 350px;

		@include breakpoint(min-mobile) {
			margin-left: auto;
			min-width: 290px;
		}

		&:before {
			background-color: $input-with-icon-b-color;
			bottom: 0;
			content: '';
			height: 48px;
			left: auto;
			pointer-events: none;
			position: absolute;
			right: 0;
			width: 48px;
		}

		.input {
			padding-inline-end: 64px;
		}

		button.input-with-icon__suffix {
			height: 48px;
			right: 0;
			top: 0;
			transform: none;
			width: 48px;
			color: #fff;
			font-size: 20px;
			left: auto;
			position: absolute;
		}
	}

	.icon {
		fill: currentColor;
		height: 1em;
		overflow: hidden;
		pointer-events: none;
		vertical-align: middle;
		width: 1em;
	}

	.button--reset {
		background: none;
		border: 0;
		color: inherit;
		cursor: pointer;
		line-height: 1;
		outline: none;
		padding: 0;
	}
}

.table-sorter {
	position: relative;

	&:after,
	&:before {
		content: '';
		height: 0;
		width: 0;
		position: absolute;
		right: -15px;
		border-left: 4px solid transparent;
		border-right: 4px solid transparent;
		transform: translateY(50%);
		//opacity: 0.48;
	}

	&:before {
		border-top: 4px solid $table-sorter-color;
		top: 50%;
	}

	&:after {
		border-bottom: 4px solid $table-sorter-color;
		border-top: 4px solid transparent;
		bottom: calc(50% + 5px);
	}
}

.asc.table-sorter:before,
.desc.table-sorter:after {
	opacity: 0;
}
