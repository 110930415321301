$root: '.pagination';

// Design Tokens
$pagination-item-padding: 8px;
$pagination-item-horizontal-spacing: 32px;
$pagination-item-spread-horizontal-spacing: 72px;

#{$root} {
	&__list {
		@extend %list-reset;
		display: flex;
		align-items: center;
	}

	&__item {
		margin-right: $pagination-item-horizontal-spacing;
		@include breakpoint(phablet) {
			margin-right: 8px;
		}

		&:last-child {
			margin-right: 0;
		}
	}

	&__link {
		display: inline-block;
		padding: $pagination-item-padding;
		color: $pagination-item-color;
		text-decoration: none;

		&--active {
			text-decoration: underline;
		}
	}
}

// Variations

// Variation where the first and last item have extra space.
#{$root}--spread {
	#{$root}__item {
		&:first-child,
		&:nth-last-child(2) {
			margin-right: $pagination-item-spread-horizontal-spacing;
			@include breakpoint(phablet) {
				margin-right: 16px;
			}
		}
	}
}

#{$root}--center {
	margin: 40px auto 20px;

	#{$root}__list {
		justify-content: center;
	}
}
