$switch-color: #d0d6d5;
$switch-color-active: $color-brand-primary;

.switch {
	position: relative;
	display: inline-block;
	width: 40px;
	height: 24px;

	input {
		opacity: 0;
		width: 0;
		height: 0;

		&:checked + .control__indicator-switch {
			background-color: $switch-color-active;
		}

		&:checked + .control__indicator-switch:before {
			transform: translateX(16px);
		}
	}

	.control__indicator-switch {
		position: absolute;
		cursor: pointer;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: $switch-color;
		transition: background 0.3s ease;
		border-radius: 34px;

		&:before {
			position: absolute;
			content: '';
			height: 20px;
			width: 20px;
			left: 2px;
			bottom: 2px;
			background-color: white;
			transition: background 0.3s ease;
			border-radius: 50%;
		}
	}
}
