@import 'font';

body {
	font-family: $primary-font;
	font-size: $global-font-size;
	color: $global-font-color;
	line-height: $global-line-height;
	margin: 0;
	-webkit-font-smoothing: antialiased;
}

a {
	color: $global-font-color;
}

input,
select,
textarea,
button {
	font: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-size: inherit;
	margin: 0;
}

%heading-1 {
	@include font-size(40px, 44px);
}

%heading-2 {
	@include font-size(36px, 42px);
}

%heading-3 {
	@include font-size(28px, 34px);
}

%heading-4 {
	@include font-size(20px, 26px);
}

%heading-5 {
	@include font-size(18px, 24px);
}

h1 {
	@extend %heading-1;
}

h2 {
	@extend %heading-2;
}

h3 {
	@extend %heading-3;
}

h4 {
	@extend %heading-4;
}

h5 {
	@extend %heading-5;
}

p {
	margin: 0;
	@include font-size(16px, 22px);
	font-weight: 400;
}

.icon {
	fill: currentColor;
	width: 1em;
	height: 1em;
	vertical-align: middle;
	overflow: hidden;
}
